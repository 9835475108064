import React, { useState, useEffect } from 'react';
import './EquityWynWatchList.css';
import { useLocation, useNavigate } from 'react-router-dom';
import EquityWynEditStockModal from './EquityWynEditStockModal';
import ConfirmationModal from '../ConfirmationModal';

import config from '../../config/config';

function EquityWynWatchList() {
    const location = useLocation();
    const { isUserAdmin } = location.state || {};
    const navigate = useNavigate();
    const [stocks, setStocks] = useState([]);
    const [selectedStock, setSelectedStock] = useState(null);
    const [showEditStockModal, setShowEditStockModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        fetchStocks();
    }, []);

    const fetchStocks = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }
            const response = await fetch(`${config.API_URL}/get-equity-stocks`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching stocks');
            }
            const data = await response.json();
            const watchListStocks = data.filter(stock => stock.equity_status === 'WATCH');
            setStocks(watchListStocks);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const updateStockStatus = (stock) => {
        setSelectedStock(stock);
        setShowEditStockModal(true);
    };

    const deleteStock = (stock) => {
        setSelectedStock(stock);
        setShowConfirmModal(true);
    };

    const deleteStockDetals = async (stock) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }
            const response = await fetch(`${config.API_URL}/delete-equity-stock`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: stock.id,
                }),
            });
            if (!response.ok) {
                throw new Error('Error deleting stock');
            }
            const data = await response.json();
            console.log('Delete successful:', data);
            setShowConfirmModal(false);
            fetchStocks();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (!isUserAdmin) {
        return <div><h1>Not Authorized</h1></div>;
    }

    return (
        <div className="equity-wyn-container">
            <div className="action-buttons-equitywyn-watch-list">
                <button className="back-button-watch-list" onClick={() => navigate(-1)}>Back</button>
            </div>
            <h2 className='equitywyn-title' style={{ marginTop: '5px', color: '#ff593f', fontWeight: 'bold' }}>EquityWyn - Watch List</h2>
            <div className="watch-list-table">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Ticker Code</th>
                            <th>Stock Name</th>
                            <th>Status</th>
                            <th>CMP</th>
                            <th>Buy Range</th>
                            <th>Target</th>
                            <th>SL</th>
                            <th>Horizon</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stocks.map((stock, index) => (
                            <tr key={index}>
                                <td>{stock.ticker_code}</td>
                                <td>{stock.stock_name}</td>
                                <td>{stock.equity_status.toUpperCase()}</td>
                                <td>{stock.current_price}</td>
                                <td>{stock.buy_range}</td>
                                <td>{stock.target_price}</td>
                                <td>{stock.stop_loss}</td>
                                <td>{stock.time_frame}</td>
                                <td className="table-action-buttons">
                                    <button className="edit-button" onClick={() => updateStockStatus(stock)}>
                                        Edit
                                    </button>
                                    <button className="delete-button" onClick={() => deleteStock(stock)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal for Editing Stock */}
            {showEditStockModal && (
                <EquityWynEditStockModal
                    stock={selectedStock}
                    onClose={() => setShowEditStockModal(false)}
                />
            )}

            {/* Modal for Confirming Stock Deletion */}
            <ConfirmationModal
                isOpen={showConfirmModal}
                message="Are you sure you want to delete this stock from your watch list?"
                onConfirm={() => deleteStockDetals(selectedStock)}
                onCancel={() => setShowConfirmModal(false)}
            />
        </div>
    );
}

export default EquityWynWatchList;