import React, { useState, useEffect } from 'react';
import './AddSubscriberModal.css';

import config from '../config/config';

function AddSubscriberModal({ onClose }) {
  const [users, setUsers] = useState([]);
  const [plans, setPlans] = useState([]);
  const [userId, setUserId] = useState("");
  const [planId, setPlanId] = useState("");
  const [paidDate, setPaidDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [telegramId, setTelegramId] = useState("");
  const [planPeriod, setPlanPeriod] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
    fetchPlans();
  }, []);

  const fetchUsers = async () => {
    try {
      const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
      const response = await fetch(`${config.API_URL}/get-users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });
      if (!response.ok) throw new Error("Failed to fetch users");
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      setErrorMessage("Error fetching users. Please try again.");
    }
  };

  const fetchPlans = async () => {
    try {
      const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
      const response = await fetch(`${config.API_URL}/get-algo-plans`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error("Failed to fetch plans");
      const data = await response.json();
      setPlans(data);
    } catch (error) {
      setErrorMessage("Error fetching plans. Please try again.");
    }
  };

  const handlePlanChange = (e) => {
    const selectedPlanId = e.target.value;
    setPlanId(selectedPlanId);

    // Find and set the selected plan's period
    const selectedPlan = plans.find((plan) => plan.id === selectedPlanId);
    if (selectedPlan) {
      setPlanPeriod(selectedPlan.Period);

      // Recalculate due date if paidDate is already selected
      if (paidDate) {
        calculateDueDate(paidDate, selectedPlan.Period);
      }
    }
  };

  const handlePaidDateChange = (e) => {
    const selectedPaidDate = e.target.value;
    setPaidDate(selectedPaidDate);

    // Recalculate due date when paid date changes and planPeriod is available
    if (planPeriod) {
      calculateDueDate(selectedPaidDate, planPeriod);
    }
  };

  const calculateDueDate = (paidDate, period) => {
    const paidDateObj = new Date(paidDate);
    paidDateObj.setDate(paidDateObj.getDate() + period); 
    const formattedDueDate = paidDateObj.toISOString().split("T")[0]; 
    setDueDate(formattedDueDate); 
  };

  const handleSave = async () => {
    if (!userId || !planId || !paidDate) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      const token = localStorage.getItem('token');
        if (!token) {
        throw new Error('No token found, please login');
        }
      const response = await fetch(
        `${config.API_URL}/save-new-subscription-details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: JSON.stringify({
            user_id: userId,
            plan_id: planId,
            last_paid_date: paidDate,
            next_due_date: dueDate,  
            telegram_id: telegramId,
            reminder_count: 0,
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to save subscription details");

      const data = await response.json();
      console.log("Save successful:", data);
      onClose();
    } catch (error) {
      setErrorMessage("Error saving subscription details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-subscriber-modal">
      <div className="modal-content">
        <h2>Add Subscriber</h2>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <label>
          Select User
          <select value={userId} onChange={(e) => setUserId(e.target.value)}>
            <option value="">Select User</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.user_name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Select Plan
          <select value={planId} onChange={handlePlanChange}>
            <option value="">Select Plan</option>
            {plans.map((plan) => (
              <option key={plan.id} value={plan.id}>
                {plan.Name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Paid Date
          <input type="date" value={paidDate} onChange={handlePaidDateChange} />
        </label>
        <label>
          Telegram ID (Optional)
          <input
            type="text"
            value={telegramId}
            onChange={(e) => setTelegramId(e.target.value)}
            placeholder="Enter Telegram ID"
          />
        </label>
        <div className="modal-buttons">
          <button
            className="save-button"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button className="close-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddSubscriberModal;