import React from 'react';
import logo from '../logo.png';
import './JobStatus.css';
import { useNavigate } from 'react-router-dom';

const JobStatus = () => {
    const navigate = useNavigate();

    const handleRunAlgo = () => {
    navigate('/setupAlgo');
    };

  return (
    <div className="job-status-container">
      <button className="run-algo-button" onClick={handleRunAlgo}>Run Algo</button>
      <img src={logo} className="App-logo" alt="logo"/>
      <h2>Job Status</h2>
    </div>
  );
};


export default JobStatus;