import React, { useState } from 'react';
import './ForgetPassword.css';
import logo from '../logo.png';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import config from '../config/config';

function ForgetPassword() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handlePasswordReset = (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        const resetData = {
            user_id: username,
            new_password: password
        };

        console.log('Sending password reset data:', resetData);

        fetch(`${config.API_URL}/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(resetData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(err => { throw err; });
                }
                return response.json();
            })
            .then(data => {
                console.log('Success:', data);
                toast.success('Password reset successful');
                navigate('/login');
            })
            .catch((error) => {
                console.error('Error:', error);
                toast.error('Password reset failed');
            });
    };

    return (
        <div className="Login-container">            
            <img src={logo} className="App-logo" alt="logo" />
            <h2>Forget Password</h2>
            <form onSubmit={handlePasswordReset}>
                <div className="form-group">
                    <label>Username</label>
                    <input
                        type="text"
                        value={username}
                        placeholder='Enter your username'
                        onChange={handleUsernameChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>New Password</label>
                    <input
                        type="password"
                        placeholder='Enter your new password'
                        value={password}
                        onChange={handlePasswordChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Confirm New Password</label>
                    <input
                        type="password"
                        placeholder='Confirm your new password'
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                    />
                </div>
                <button type="submit">Reset Password</button>
            </form>
            <ToastContainer />
        </div>
    );
}

export default ForgetPassword;