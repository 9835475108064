import {React} from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./UserProfile.css";
import { useNavigate, useLocation } from "react-router-dom";
// import BrokerSettingsModal from "./BrokerSettingsModal";

const UserProfilePageDark = () => {
    
    const navigate = useNavigate();
    const location = useLocation();
    const { username, algoUser, equityUser } = location.state || {}; // Fallback to {} if state is undefined

    console.log('Retrieved state:', { username, algoUser, equityUser });
    // const [showBrokerSettings, setShowBrokerSettings] = useState(false);

  return (
    <div className="container my-5 p-4 rounded" style={{ backgroundColor: "#1a1a1a", color: "#ffffff" }}>
      {/* Profile Header */}
      <div className="card bg-secondary text-white mb-4">
        <div className="card-body text-center">
          <h3 className="card-title">abbie</h3>
        </div>
      </div>

      {/* Profile Details */}
      <div className="row">
        <div className="col-lg-4">
          <div className="card bg-dark text-light mb-4 border-secondary">
            <div className="card-header bg-primary text-white">Personal Info</div>
            <div className="card-body">
              <p><strong>First Name:</strong> Abhishek</p>
              <p><strong>Last Name:</strong> Sahu</p>
              <p><strong>Email:</strong> abhishek@gmail.com</p>
              <p><strong>Phone:</strong> +91 7052421175</p>
            </div>
          </div>
        </div>

        <div className="col-lg-8">
          <div className="card bg-dark text-light mb-4 border-secondary">
            <div className="card-header bg-primary text-white">Subscriptions</div>
            <div className="card-body">
              <ul className="list-group">
                <li className="list-group-item bg-dark text-light border-secondary">Algowyn - Active</li>
                <li className="list-group-item bg-dark text-light border-secondary">EquityWyn - Active</li>
                <li className="list-group-item bg-dark text-light border-secondary">EquityWyn Premium - Unactive</li>
              </ul>
            </div>
          </div>

          <div className="card bg-dark text-light border-secondary">
            <div className="card-header bg-dark text-white">Account Settings</div>
            <div className="card-body">
              <button className="btn btn-primary">Edit Profile</button>
              <button className="btn btn-primary me-3" onClick={() => navigate('/broker-settings')}>Broker Settings <i className="bi bi-gear-fill"></i></button>
              <button className="btn btn-primary me-3" onClick={() => navigate('/forget-password')}>Change Password</button>
              <button className="btn btn-outline-secondary">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfilePageDark;