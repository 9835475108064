import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import './ExitWSModal.css';
import MessageModal from './MessageModal';

import config from '../config/config';

const ExitWSModal = ({ isOpen, onClose, orders }) => {
    const [idNum, setIdNum] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const customSelectStyles = {
        control: (provided) => ({
          ...provided,
          backgroundColor: '#333',
          color: '#f0f0f0',
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: '#333',
          color: '#f0f0f0',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#f0f0f0',
        }),
        multiValue: (provided) => ({
          ...provided,
          backgroundColor: '#444',
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          color: '#f0f0f0',
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#666' : state.isFocused ? '#555' : '#333',
          color: '#f0f0f0',
        }),
    };    

    useEffect(() => {
    if (isOpen) {
        setIdNum('');
        setUsers([]);
        setSelectedUsers({ ALL: true });
    }
    }, [isOpen]);

    useEffect(() => {
        console.log('Orders:', orders);
        console.log('Selected ID Number:', idNum);
    
        if (idNum) {
          const filteredOrders = orders.filter(order => order.id_num === parseInt(idNum));
          console.log('Filtered Orders Length:', filteredOrders.length);
          console.log('Filtered Orders:', filteredOrders);
    
          if (filteredOrders.length > 0) {
            const uniqueUsers = [...new Set(filteredOrders.map(order => ({ value: order.user_id, label: order.user_name })))];
            setUsers(uniqueUsers);
            setSelectedUsers({ ALL: true });
          } else {
            setUsers([]);
            setSelectedUsers({ ALL: true });
          }
        }
    }, [idNum, orders]);

    const handleSubmit = () => {
        const userIds = selectedUsers.ALL
          ? 'ALL'
          : Object.keys(selectedUsers).join(', ');

        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
    
        fetch(`${config.API_URL}/exit-all`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({
                id_num: idNum,
                user_id_list: userIds
            })
          })
          .then(async (response) => {
            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(errorText);
            }
            return response.json();
          })
          .then(data => {
            console.log('Modify SL Response:', data);
            setPopupMessage(`${data}`);
            setShowPopup(true);
          })
          .catch(err => {
            console.log('Error:', err.message);
            setPopupMessage(`${err.message}`);
            setShowPopup(true);
          });
      };

    const handleIdNumChange = (selectedOption) => {
    setIdNum(selectedOption ? selectedOption.value : '');
    };

    const handleUserChange = (selectedOptions) => {
    const allUsersSelected = selectedOptions.length === users.length;
    const newSelectedUsers = allUsersSelected ? { ALL: true } : selectedOptions.reduce((acc, option) => {
        acc[option.value] = option.label;
        return acc;
    }, {});
    setSelectedUsers(newSelectedUsers);
    };

    const idNumOptions = [...new Set(orders.map(order => order.id_num))].map(id => ({ value: id, label: id.toString() }));

    const displaySelectedUsers = selectedUsers.ALL ? users : Object.keys(selectedUsers).map(userId => ({
        value: parseInt(userId),
        label: selectedUsers[userId]
    }));

  return isOpen ? (
    <div className="modal-overlay-exit-ws">
      <div className="modal-content">
        <h2>Exit Wholesale</h2>
        <div>
          <label>ID Number:</label>
          <Select
            options={idNumOptions}
            value={idNumOptions.find(option => option.value === parseInt(idNum))}
            onChange={handleIdNumChange}
            styles={customSelectStyles}
            isClearable
          />
        </div>
        {idNum && (
            <div>
              <label>Select User:</label>
              <Select
                isMulti
                options={users}
                value={displaySelectedUsers}
                onChange={handleUserChange}
                styles={customSelectStyles}
              />
            </div>
        )}
        <div className="modal-buttons">
            <button onClick={handleSubmit}>Submit</button>
            <button onClick={onClose}>Cancel</button>
        </div>
      </div>
      {showPopup && <MessageModal message={popupMessage} onClose={() => setShowPopup(false)} />}
    </div>
  ) : null;
};

export default ExitWSModal;