import React, { useState, useEffect } from "react";
import "./EquityWynAllCalls.css";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";

import config from '../../config/config';

function EquityWynAllCalls() {
    const location = useLocation();
    const { user_id } = location.state || {};
    const [stocks, setStocks] = useState([]);
    const [filteredStocks, setFilteredStocks] = useState([]);
    const [selectedStock, setSelectedStock] = useState('');
    const [tickerCodes, setTickerCodes] = useState([]);
    const [stocksCurrentPrice, setStocksCurrentPrice] = useState([]);
    const [fromDate, setFromDate] = useState('2024-05-01');
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);
    const [statusFilter, setStatusFilter] = useState('');
    const [priceFilter, setPriceFilter] = useState('');
    const flag = 0;
    const navigate = useNavigate();

  useEffect(() => {
    fetchStocks();
  }, []);

  const width = window.innerWidth;

  const fetchStocks = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found, please login");
      }
      const response = await fetch(`${config.API_URL}/get-equity-stocks`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Error fetching calls");
      }
      const data = await response.json();
      setStocks(data);
      setFilteredStocks(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const applyFilters = () => {
      let filtered = stocks;

      // Date filter
      filtered = filtered.filter((stock) => {
        const callDate = new Date(stock.call_date);
        return callDate >= new Date(fromDate) && callDate <= new Date(toDate);
      });

      // Stock filter
      if (selectedStock) {
        filtered = filtered.filter(
          (stock) => stock.stock_name === selectedStock
        );
      }

      // Status filter
      if (statusFilter) {
        filtered = filtered.filter(
          (stock) => stock.equity_status === statusFilter
        );
      }

      // Price filter
      if (priceFilter) {
        filtered = filtered.filter((stock) => {
          const price = stock.call_price;
          switch (priceFilter) {
            case "0-100":
              return price >= 0 && price <= 100;
            case "101-200":
              return price > 100 && price <= 200;
            case "201-500":
              return price > 200 && price <= 500;
            case "501-1000":
              return price > 500 && price <= 1000;
            case "1000+":
              return price > 1000;
            default:
              return true;
          }
        });
      }

      // Sort by equity_status, placing 'OPEN' cards first
      filtered = filtered.sort((a, b) => {
        if (a.equity_status === "OPEN" && b.equity_status !== "OPEN") {
          return -1; // 'OPEN' comes first
        } else if (a.equity_status !== "OPEN" && b.equity_status === "OPEN") {
          return 1; // 'CLOSED' comes after
        }
        return 0; // Maintain order for the same status
      });

      setFilteredStocks(filtered);
    };

    applyFilters();
  }, [stocks, fromDate, toDate, selectedStock, statusFilter, priceFilter]);

  useEffect(() => {
    if (stocks && stocks.length > 0) {
      const tickerCodes = stocks.map((equity) => equity.ticker_code);
      setTickerCodes(tickerCodes);
    }
  }, [stocks]);

  useEffect(() => {
    if (tickerCodes && tickerCodes.length > 0) {
      fetchStocksCMP(tickerCodes);
    }
  }, [tickerCodes]);

  const fetchStocksCMP = async (tickerCodes) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found, please login");
      }
      const response = await fetch(`${config.API_URL}/get-equity-stocks-cmp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ticker_codes: tickerCodes }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching stocks: ${response.statusText}`);
      }
      const data = await response.json();
      setStocksCurrentPrice(data);
    } catch (error) {
      console.error("Error in fetchStocksCMP:", error);
    }
  };

  const formatData = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = String(date.getUTCFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  const handleCardClick = (tickerCode) => {
    navigate("/equitywyn/stock-details", { state: { tickerCode } });
  };

  if (!user_id) {
    return (
      <div>
        <h1>Not Authorised</h1>
      </div>
    );
  }

  return (
    <div>
      <main className="equity-wyn-container">
        <div className="action-buttons-equitywyn-all-calls">
          <button
            className="back-button-all-calls"
            onClick={() => navigate(-1, { state: { user_id } })}
          >
            Back
          </button>
        </div>
        <h2
          className="equitywyn-title"
          style={{ marginTop: "20px", color: "#ff593f", fontWeight: "bold" }}
        >
          EquityWyn - All Calls
        </h2>
        <div className="filters-all-calls">
          <div className="date-selection">
            <label>
              From Date:
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </label>
            <label>
              To Date:
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </label>
          </div>
            <div className="selection-filters-all-calls">
              <div className="dropdown-selection">
                <label>
                  Select Stock
                  <select
                    value={selectedStock}
                    onChange={(e) => setSelectedStock(e.target.value)}
                  >
                    <option value="">ALL</option>
                    {stocks
                      .slice() // Create a shallow copy of stocks to avoid mutating state
                      .sort((a, b) => a.stock_name.localeCompare(b.stock_name))
                      .map((stock) => (
                        <option key={stock.id} value={stock.stock_name}>
                          {stock.stock_name}
                        </option>
                      ))}
                  </select>
                </label>
              </div>
              <div className="dropdown-selection-filter">
                <label>
                  Status Filter
                  <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="BOOKLOSS">Book Loss</option>
                    <option value="BOOKPROFIT">Book Profit</option>
                    <option value="CLOSED">Closed</option>
                    <option value="OPEN">Open</option>
                  </select>
                </label>
              </div>
              <div className="dropdown-selection-filter">
                <label>
                  Price Filter
                  <select
                    value={priceFilter}
                    onChange={(e) => setPriceFilter(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="0-100">0-100</option>
                    <option value="101-200">101-200</option>
                    <option value="201-500">201-500</option>
                    <option value="501-1000">501-1000</option>
                    <option value="1000+">1000+</option>
                  </select>
                </label>
              </div>
            </div>
        </div>

        {flag === 1 && (
          <div className="card-container">
            {filteredStocks
              .filter((call) => call.equity_status !== "WATCH")
              .map((call, index) => {
                const currentPrice =
                  stocksCurrentPrice[call.ticker_code] || "N/A";

                return (
                  <div
                    key={index}
                    className={`equity-card ${
                      call.equity_status === "OPEN"
                        ? "open-card"
                        : call.equity_status === "CLOSED"
                        ? "closed-card"
                        : call.equity_status === "BOOKLOSS"
                        ? "loss-card"
                        : call.equity_status === "BOOKPROFIT"
                        ? "profit-card"
                        : ""
                    }`}
                    onClick={() => handleCardClick(call.ticker_code)}
                  >
                    {call.equity_status}
                    <div className="equity-card-header">
                      <div>
                        <h3>
                          {call.stock_name.length > 30
                            ? call.stock_name.substring(0, 30) + "..."
                            : call.stock_name}
                        </h3>
                        CMP: {currentPrice}
                      </div>
                      <div className="buy-info">
                        <div className="buy-text">Rs.{call.buy_range}</div>
                        <div className="entity">Buy Range</div>
                      </div>
                    </div>
                    <div className="line"></div>
                    <div className="info-grid">
                      <p>
                        <strong>{call.target_price}</strong>Target Price
                      </p>
                      <p>
                        <strong>{call.stop_loss}</strong>Stop Loss
                      </p>
                      <p>
                        <strong>
                          {(
                            ((call.target_price - call.call_price) /
                              call.call_price) *
                            100
                          ).toFixed(2)}
                          %
                        </strong>
                        Exp. Profit
                      </p>
                    </div>
                    Call Date: <b>{formatData(call.call_date)}</b> &nbsp;
                    Horizon: <b>{call.time_frame}</b>
                  </div>
                );
              })}
          </div>
        )}

        {flag === 0 && (
          <div className="all-calls-table">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Stock Name</th>
                  <th>CMP</th>
                  {width > 578 && <th>Target</th>}
                  {width > 578 && <th>SL</th>}
                  {width > 578 && <th>Exp. Profit</th>}
                  <th>Call Date</th>
                  {width > 578 && <th>Horizon</th>}
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredStocks
                  .filter((call) => call.equity_status !== "WATCH")
                  .map((call, index) => {
                    const currentPrice =
                      stocksCurrentPrice[call.ticker_code] || "N/A";

                    return (
                      <tr key={index}>
                        <td>
                          <span
                            className="clickable-stock-name"
                            onClick={() => handleCardClick(call.ticker_code)}
                            style={{
                              cursor: "pointer",
                              color: "#2880eb",
                              textDecoration: "underline",
                            }} // optional inline styling
                          >
                            {call.stock_name}
                          </span>
                        </td>
                        <td>{currentPrice}</td>
                        {width > 578 && <td>{call.target_price}</td>}
                        {width > 578 && <td>{call.stop_loss}</td>}
                        {width > 578 && (
                          <td>
                            {(
                              ((call.target_price - call.call_price) /
                                call.call_price) *
                              100
                            ).toFixed(2)}
                            %
                          </td>
                        )}
                        <td>{formatData(call.call_date)}</td>
                        {width > 578 && <td>{call.time_frame}</td>}
                        <td>{call.equity_status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default EquityWynAllCalls;
