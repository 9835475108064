import React from 'react';
import './Navbar.css';
import { useNavigate } from 'react-router-dom';

function Navbar({ onNavigate, homepage }) {

  const navigate = useNavigate();

  return (
    <nav className={'Navbar algowyn-navbar'}>
      <ul>
        <li><a href="#home" onClick={(e) => onNavigate(e, 'home')}>Home</a></li>
        {homepage === 'algowyn' && <li><a href="#strategies" onClick={(e) => onNavigate(e, 'strategies')}>Strategies</a></li>}
        <li><a href="#faq" onClick={(e) => onNavigate(e, 'faq')}>FAQ</a></li>
        <li><a href="#aboutus" onClick={(e) => onNavigate(e, 'aboutus')}>About Us</a></li>
      </ul>
      <div className="Navbar-buttons">
        <span style={{ color: 'white', fontSize: '1.25rem' }}>support@wynbytech.com</span>
        <button className="login-button-navbar" onClick={() => navigate('/login')}>Login</button>
        <button className="register-button-navbar" onClick={() => navigate('/registration')}>Sign Up</button>
      </div>
    </nav>
  );
}

export default Navbar;