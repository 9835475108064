import React, { useState } from 'react';
import './PayModal.css';

import config from '../config/config';

function PayModal({ userId, strategy, dueDate, lastPaidDate, onClose }) {
  const [paidDate, setPaidDate] = useState('');
  const [days, setDays] = useState(30);
  const [lotSize, setLotSize] = useState(strategy === 'DHONI' ? 25 : 15);
  const [targetAmount, setTargetAmount] = useState(1000);
  const [lossAmount, setLossAmount] = useState(500);

  const increment = strategy === 'DHONI' ? 25 : 15;


  const handleDaysChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    const increment = 30; // Days should increment/decrement in multiples of 30
  
    if (!isNaN(newValue)) {
      const remainder = newValue % increment;
      const adjustedValue = remainder === 0 ? newValue : newValue + (increment - remainder);
      setDays(adjustedValue);
    }
  };
  
  const handleDaysKeyDown = (e) => {
    const increment = 30; // Days should increment/decrement in multiples of 30
    
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setDays((prev) => prev + increment);
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setDays((prev) => Math.max(prev - increment, 0)); // Ensure it doesn't go below 0
    }
  };
  

  const handleLotSizeChange = (e) => {
    const newValue = parseInt(e.target.value, 10);

    if (!isNaN(newValue)) {
      const remainder = newValue % increment;
      const adjustedValue = remainder === 0 ? newValue : newValue + (increment - remainder);
      setLotSize(adjustedValue);
    }
  };

  const handleLotSizeKeyDown = (e) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setLotSize((prev) => prev + increment);
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setLotSize((prev) => Math.max(prev - increment, 0)); // Ensure it doesn't go below 0
    }
  };

  const handleSave = async () => {
    try {
      let newDueDate;
      const selectedPaidDate = new Date(paidDate);
      const selectedDueDate = new Date(dueDate);

      if (selectedPaidDate <= selectedDueDate) {
        newDueDate = new Date(dueDate);
        newDueDate.setDate(newDueDate.getDate() + parseInt(days));
      } else {
        newDueDate = new Date(paidDate);
        newDueDate.setDate(newDueDate.getDate() + parseInt(days));
      }
      const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
      const response = await fetch(`${config.API_URL}/save-subscription-details`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`  
        },
        body: JSON.stringify({
          user_id: userId,
          strategy: strategy,
          paid_date: paidDate,
          new_due_date: newDueDate.toISOString().split('T')[0],
          lot_size: lotSize,
          target_amount: targetAmount,
          loss_amount: lossAmount,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save subscription details');
      }

      const data = await response.json();
      console.log('Save successful:', data);
      onClose();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="pay-modal">
      <div className="modal-content">
        <h2>Pay for User ID {userId} on Strategy {strategy}</h2>
        <label>
          Paid Date
          <input type="date" value={paidDate} onChange={(e) => setPaidDate(e.target.value)} />
        </label>
        <label>
          Days
          <input
            type="number"
            value={days}
            onInput={handleDaysChange}
            onKeyDown={handleDaysKeyDown}
          />
        </label>
        <label>
          Lot Size
          <input
            type="number"
            value={lotSize}
            onInput={handleLotSizeChange}
            onKeyDown={handleLotSizeKeyDown}
          />
        </label>
        <label>
          Target Amount
          <input type="number" value={targetAmount} onChange={(e) => setTargetAmount(e.target.value)} />
        </label>
        <label>
          Loss Amount
          <input type="number" value={lossAmount} onChange={(e) => setLossAmount(e.target.value)} />
        </label>
        <div className="modal-buttons">
          <button className="save-button" onClick={handleSave}>Pay</button>
          <button className="close-button" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default PayModal;