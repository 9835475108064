import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import './StartStopAlgoModal.css';
import MessageModal from './MessageModal';

import config from '../config/config';

const StartStopAlgoModal = ({ onClose }) => {
  const [algoData, setAlgoData] = useState([]);
  const [selectedAction, setSelectedAction] = useState('start');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      color: '#f0f0f0',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      color: '#f0f0f0',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#f0f0f0',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#444',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#f0f0f0',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#666' : state.isFocused ? '#555' : '#333',
      color: '#f0f0f0',
    }),
  };

  const fetchAlgoData = async () => {
    try {
      const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
      const response = await fetch(`${config.API_URL}/get-algo-status`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching algo data');
      }
      const data = await response.json();
      setAlgoData(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchAlgoData();
  }, []);

  useEffect(() => {
    const filtered = algoData.filter(user =>
      selectedAction === 'start' ? user.stop_algo === 0 : user.stop_algo === 1
    );
    setFilteredUsers(filtered);
    setSelectedUsers(filtered.map(user => ({ value: user.user_id, label: user.user_name })));
  }, [selectedAction, algoData]);

  const handleSubmit = () => {
    const userIds = selectedUsers.map(user => user.value);
    const newStatus = selectedAction === 'start' ? 1 : 0;
    fetch(`${config.API_URL}/algo/status/update-all`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_id_list: userIds,
        status: newStatus,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(errorText);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Start/Stop Algo Response:', data);
        setPopupMessage(`Response: ${JSON.stringify(data)}`);
        setShowPopup(true);
      })
      .catch((err) => {
        console.log('Error:', err.message);
        setPopupMessage(`Error: ${err.message}`);
        setShowPopup(true);
      });
  };

  const handleUserChange = (selectedOptions) => {
    setSelectedUsers(selectedOptions);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Start/Stop Algo</h2>
        <div>
          <select
            value={selectedAction}
            onChange={(e) => setSelectedAction(e.target.value)}
          >
            <option value="start">Start</option>
            <option value="stop">Stop</option>
          </select>
        </div>
        {filteredUsers.length > 0 && (
          <div>
            <label>Select Users:</label>
            <Select
              isMulti
              styles={customSelectStyles}
              options={filteredUsers.map(user => ({ value: user.user_id, label: user.user_name }))}
              value={selectedUsers}
              onChange={handleUserChange}
              defaultValue={filteredUsers.map(user => ({ value: user.user_id, label: user.user_name }))}
            />
          </div>
        )}
        <div className="modal-buttons">
          <button onClick={handleSubmit}>Submit</button>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
      {showPopup && (
        <MessageModal
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default StartStopAlgoModal;