import React, { useState, useEffect } from 'react';
import './Logs.css';
import { useNavigate } from 'react-router-dom';

import config from '../config/config';

function Logs() {
    const navigate = useNavigate();
    const getTodayDateTime = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const getTodayStartDateTime = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const hours = '09';
        const minutes = '15';
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const [fromDate, setFromDate] = useState(getTodayStartDateTime());
    const [toDate, setToDate] = useState(getTodayDateTime());
    const [logs, setLogs] = useState([]);
    const [logLevel, setLogLevel] = useState('All');
    const [appId, setAppId] = useState('All');
    const [ filteredLogs, setFilteredLogs ] = useState([]);

    const handleBack = () => {
      navigate('/monitor');
    };

    const handleFromDateChange = (event) => setFromDate(event.target.value);
    const handleToDateChange = (event) => setToDate(event.target.value);
    const handleLogLevelChange = (event) => setLogLevel(event.target.value);
    const handleAppIdChange = (event) => setAppId(event.target.value);

    const handleSearch = (event) => {
      event.preventDefault();
      fetchLogs();
    };

    const fetchLogs = async () => {
        try {
            const token = localStorage.getItem('token');
                if (!token) {
                throw new Error('No token found, please login');
                }
            const response = await fetch(`${config.API_URL}/get-logs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({
                    from_date: formatDateTime(fromDate),
                    to_date: formatDateTime(toDate),
                }),
            });
            if (!response.ok) {
                throw new Error('Error fetching logs');
            }
            const data = await response.json();
            setLogs(data);
            console.log('Logs', data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        let filtered = logs;
        if (logLevel !== 'All') {
          filtered = filtered.filter(log => log.log_level === parseInt(logLevel));
        }
        if (appId !== 'All') {
          filtered = filtered.filter(log => log.id_num === parseInt(appId));
        }
        setFilteredLogs(filtered);
    }, [logs, logLevel, appId]);


  return (
    <div className="logs-container">
        <div className="action-buttons-logs">
            <button className="back-button-logs" onClick={handleBack}>Back</button>
        </div>
      <h2>Logs</h2>
        <div className = "filters">
            <div className="date-selection">
            <label>
                From Date:
                <input type="datetime-local" value={fromDate} onChange={handleFromDateChange} />
            </label>
            <label>
                To Date:
                <input type="datetime-local" value={toDate} onChange={handleToDateChange} />
            </label>
            </div>
            <div className="log-level-selection">
                <label>
                    Log Level:
                    <select value={logLevel} onChange={handleLogLevelChange}>
                        <option value="All">All</option>
                        <option value="0">Info</option>
                        <option value="1">Error</option>
                        <option value="2">Warning</option>
                    </select>
                </label>
            </div>
            <div className="app-id-selection">
                <label>
                    App ID:
                    <select value={appId} onChange={handleAppIdChange}>
                        <option value="All">All</option>
                        <option value="999">DHONI</option>
                        <option value="888">GAVASKAR</option>
                    </select>
                </label>
            </div>
            <button className="search-button" onClick={handleSearch}>Search</button>
        </div>

        <div className="logs-table">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>UserName</th>
                        <th>Date</th>
                        <th>Message</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredLogs.map((log, index) => (
                        <tr key={index}>
                            <td>{log.user_name}</td>
                            <td>{log.log_datetime}</td>
                            <td>{log.message}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>

  );
}

export default Logs;    