import React, { useState, useEffect } from 'react';
import './Subscription.css';
import { useNavigate } from 'react-router-dom';
import PayModal from './PayModal';
import AddSubscriberModal from './AddSubscriberModal';

import config from '../config/config';

function Subscription() {

    const navigate = useNavigate();
    const [subscriptionDetails, setSubscriptionDetails] = useState([]);
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
    const [showPayModal, setShowPayModal] = useState(false);
    const [showAddSubscriberModal, setShowAddSubscriberModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedStrategy, setSelectedStrategy] = useState(null);
    const [selectedDueDate, setSelectedDueDate] = useState(null);
    const [selectedLastPaidDate, setSelectedLastPaidDate] = useState(null);
    const [statusFilter, setStatusFilter] = useState('');
    const [dueDateFrom, setDueDateFrom] = useState('');
    const [dueDateTo, setDueDateTo] = useState('');

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = String(date.getUTCFullYear()).slice(-2);
        return `${day}/${month}/${year}`;
    };

    const calculateDaysUntilDue = (dueDate) => {
        const currentDate = new Date();
        const due = new Date(dueDate);
        const difference = Math.ceil((due - currentDate) / (1000 * 60 * 60 * 24));
        return difference;
    };

    const getUsernameStyle = (daysUntilDue) => {
        if (daysUntilDue <= 0) {
            return { color: 'red' };
        } else if (daysUntilDue < 5) {
            return { color: 'orange' };
        } else if (daysUntilDue <= 10) {
            return { color: 'yellow' };
        }
        return {};
    };

    const handleBack = () => {
        navigate('/monitor');
    };

    const handleAddSubscriber = () => {
        setShowAddSubscriberModal(true);
    };

    const fetchSubscriptionDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found, please login');
            }
            const response = await fetch(`${config.API_URL}/get-subscription-details`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching subscription details');
            }
            const data = await response.json();
            setSubscriptionDetails(data);
            setFilteredSubscriptions(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchSubscriptionDetails();
    }, []);

    useEffect(() => {
        const applyFilters = () => {
            let filtered = subscriptionDetails;

            // Status filter
            if (statusFilter) {
                filtered = filtered.filter(subscription => {
                    const daysUntilDue = calculateDaysUntilDue(subscription.due_date);
                    return (statusFilter === 'Active' && daysUntilDue > 0) ||
                           (statusFilter === 'Expired' && daysUntilDue <= 0);
                });
            }

            // Due Date (From and To) filter
            if (dueDateFrom || dueDateTo) {
                filtered = filtered.filter(subscription => {
                    const dueDate = new Date(subscription.due_date);
                    const fromDate = dueDateFrom ? new Date(dueDateFrom) : null;
                    const toDate = dueDateTo ? new Date(dueDateTo) : null;

                    // Check if the due date is within the range
                    return (!fromDate || dueDate >= fromDate) && (!toDate || dueDate <= toDate);
                });
            }

            // Sort the filtered subscriptions by the days until due date (ascending)
            filtered.sort((a, b) => calculateDaysUntilDue(a.due_date) - calculateDaysUntilDue(b.due_date));

            setFilteredSubscriptions(filtered);
        };

        applyFilters();
    }, [subscriptionDetails, statusFilter, dueDateFrom, dueDateTo]);

    const handlePay = (userId, strategy, dueDate, lastPaidDate) => {
        setSelectedUserId(userId);
        setSelectedStrategy(strategy);
        setSelectedDueDate(dueDate);
        setSelectedLastPaidDate(lastPaidDate);
        setShowPayModal(true);
    };

    const handleCloseModal = () => {
        setShowAddSubscriberModal(false);
        setShowPayModal(false);
        setSelectedUserId(null);
        fetchSubscriptionDetails();
    };

    return (
        <div className="subscription-container">
            <div className="action-buttons-subscription">
                <button className="back-button-subscription" onClick={handleBack}>Back</button>
                <button className='add-subscriber-button' onClick={handleAddSubscriber}>Add Subscriber</button>
            </div>
            <h2>Subscription Details</h2>

            {/* Filters Section */}
            <div className="filters">
                <label>
                    Status:
                    <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                        <option value="">All</option>
                        <option value="Active">Active</option>
                        <option value="Expired">Expired</option>
                    </select>
                </label>
                <label>
                    Due Date (From):
                    <input type="date" value={dueDateFrom} onChange={(e) => setDueDateFrom(e.target.value)} />
                </label>
                <label>
                    Due Date (To):
                    <input type="date" value={dueDateTo} onChange={(e) => setDueDateTo(e.target.value)} />
                </label>
            </div>

            <div className="subscription-table">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>User ID</th>
                            <th>User Name</th>
                            <th>Plans</th>
                            <th>Status (Days)</th>
                            <th>Start Date</th>
                            <th>Due Date(To)</th>
                            <th>Last Paid(From)</th>
                            <th>Paid Amount</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredSubscriptions.map((subscriptionDetail, index) => {
                            const daysUntilDue = calculateDaysUntilDue(subscriptionDetail.due_date);
                            const usernameStyle = getUsernameStyle(daysUntilDue);

                            return (
                                <tr key={index}>
                                    <td>{subscriptionDetail.user_id}</td>
                                    <td style={usernameStyle}>{subscriptionDetail.user_name}</td>
                                    <td>{subscriptionDetail.strategy}</td>
                                    <td style={usernameStyle}>{daysUntilDue > 0 ? `Active(${daysUntilDue})` : `Expired(${daysUntilDue})`}</td>
                                    <td>{subscriptionDetail.start_date != null ? formatDate(subscriptionDetail.start_date) : 'N/A'}</td>
                                    <td>{subscriptionDetail.due_date != null ? formatDate(subscriptionDetail.due_date) : 'N/A'}</td>
                                    <td>{subscriptionDetail.last_paid != null ? formatDate(subscriptionDetail.last_paid) : 'N/A'}</td>
                                    <td>{subscriptionDetail.paid_amount != null ? subscriptionDetail.paid_amount : 'N/A'}</td>
                                    <td className="action-buttons-subscription-table">
                                        <button className="pay-button-subscription" onClick={() => handlePay(subscriptionDetail.user_id, subscriptionDetail.strategy, subscriptionDetail.due_date, subscriptionDetail.last_paid)}>Pay</button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {showPayModal && <PayModal userId={selectedUserId} strategy={selectedStrategy} dueDate={selectedDueDate} lastPaidDate={selectedLastPaidDate} onClose={handleCloseModal} />}
            {showAddSubscriberModal && <AddSubscriberModal onClose={handleCloseModal} />}
        </div>
    );
};

export default Subscription;