import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './EquityWynEditStockModal.css'; 

import config from '../../config/config';

function EquityWynEditStockModal({ stock, onClose }) {
    const location = useLocation();
    const { tickerCode } = location.state; // Get the ticker code sent from the watchlist
    const [stocks, setStocks] = useState([]);
    const [selectedStock, setSelectedStock] = useState(null);
    const [id, setId] = useState('');
    const [stockName, setStockName] = useState('');
    const [symbol, setSymbol] = useState('');
    const [buyRange, setBuyRange] = useState('');
    const [callPrice, setCallPrice] = useState('');
    const [currentPrice, setCurrentPrice] = useState('');
    const [callDate, setCallDate] = useState('');
    const [stopLoss, setStopLoss] = useState('');
    const [targetPrice, setTargetPrice] = useState('');
    const [timeFrame, setTimeFrame] = useState('');
    const [equityStatus, setEquityStatus] = useState('');
    const [fundamental, setFundamental] = useState('');
    const [technical, setTechnical] = useState('');
    const [conclusion, setConclusion] = useState('');

    // Fetch stocks and pre-select the one sent from the watchlist
    useEffect(() => {
        const fetchStocks = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                throw new Error('No token found, please login');
                }
                const response = await fetch(`${config.API_URL}/get-equity-stocks`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${token}`
                    },
                });
                if (!response.ok) {
                    throw new Error('Error fetching stocks');
                }
                const data = await response.json();
                setStocks(data);

                // If a tickerCode is provided, find the corresponding stock and set it
                if (tickerCode) {
                    const stock = data.find(stock => stock.ticker_code === tickerCode);
                    if (stock) {
                        setSelectedStock(stock);
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchStocks();
    }, [tickerCode]);

    // Update form fields when the selected stock changes
    useEffect(() => {
        if(stock != null) {
            setId(stock.id || '');
            setStockName(stock.stock_name || '');
            setSymbol(stock.ticker_code || '');
            setBuyRange(stock.buy_range || '');
            setTargetPrice(stock.target_price || '');
            setStopLoss(stock.stop_loss || '');
            setTimeFrame(stock.time_frame || '');
            setCallPrice(stock.call_price || '');
            setCurrentPrice(stock.current_price || '');
            setCallDate(stock.call_date || '');
            setEquityStatus(stock.equity_status || '');
            setFundamental(stock.fundamental || '');
            setTechnical(stock.technical || '');
            setConclusion(stock.conclusion || '');
        }
        else if (selectedStock) {
            setId(selectedStock.id || '');
            setStockName(selectedStock.stock_name || '');
            setSymbol(selectedStock.ticker_code || '');
            setBuyRange(selectedStock.buy_range || '');
            setTargetPrice(selectedStock.target_price || '');
            setStopLoss(selectedStock.stop_loss || '');
            setTimeFrame(selectedStock.time_frame || '');
            setCallPrice(selectedStock.call_price || '');
            setCurrentPrice(selectedStock.current_price || '');
            setCallDate(selectedStock.call_date || '');
            setEquityStatus(selectedStock.equity_status || '');
            setFundamental(selectedStock.fundamental || '');
            setTechnical(selectedStock.technical || '');
            setConclusion(selectedStock.conclusion || '');
        }
    }, [stock, selectedStock]);

    const handleSave = async () => {
        try {
            const response = await fetch(`${config.API_URL}/update-equity-stock-details`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: id,
                    stock_name: stockName,
                    ticker_code: symbol,
                    buy_range: buyRange,
                    target_price: targetPrice,
                    stop_loss: stopLoss,
                    time_frame: timeFrame,
                    call_price: callPrice,
                    current_price: currentPrice,
                    call_date: callDate,
                    equity_status: equityStatus,
                    fundamental: fundamental,
                    technical: technical,
                    conclusion: conclusion,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to save stock');
            }

            const data = await response.json();
            console.log('Save successful:', data);
            onClose(); // Close the modal after saving
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleStockChange = (e) => {
        const selectedSymbol = e.target.value;
        setSymbol(selectedSymbol);

        // Find and set the selected stock based on the ticker code
        const stock = stocks.find(stock => stock.ticker_code === selectedSymbol);
        setSelectedStock(stock || null);
    };

    return (
        <div className="add-stock-modal">
            <div className="modal-content">
                <h2>Edit Stock</h2>
                <div className="form-row">
                <label>
                    Select Stock
                    <select
                        value={symbol}
                        onChange={handleStockChange}
                    >
                        <option value="">Select Stock</option>
                        {stocks && stocks.length > 0 ? (
                            stocks
                                .slice() // Create a shallow copy of stocks to avoid mutating state
                                .sort((a, b) => {
                                    const nameA = a.stock_name || ''; // Fallback to empty string if null
                                    const nameB = b.stock_name || ''; // Fallback to empty string if null
                                    return nameA.localeCompare(nameB);
                                })
                                .map((stock) => (
                                    <option key={stock.id} value={stock.ticker_code}>
                                        {stock.stock_name}
                                    </option>
                                ))
                        ) : (
                            <option value="" disabled>No stocks available</option>
                        )}

                    </select>
                </label>
                <label>
                    Symbol
                    <input
                        type="text"
                        value={symbol}
                        onChange={(e) => setSymbol(e.target.value)}
                    />
                </label>
                <label>
                    Call Date
                    <input
                        type="date"
                        value={callDate}
                        onChange={(e) => setCallDate(e.target.value)}
                    />
                </label>
                </div>
                <div className="form-row">
                <label>
                    Buy Range
                    <input
                        type="text"
                        value={buyRange}
                        onChange={(e) => setBuyRange(e.target.value)}
                    />
                </label>
                <label>
                    Target
                    <input
                        type="text"
                        value={targetPrice}
                        onChange={(e) => setTargetPrice(e.target.value)}
                    />
                </label>
                <label>
                    Stop Loss
                    <input
                        type="text"
                        value={stopLoss}
                        onChange={(e) => setStopLoss(e.target.value)}
                    />
                </label>
                </div>
                <div className="form-row">
                <label>
                    Horizon
                    <input
                        type="text"
                        value={timeFrame}
                        onChange={(e) => setTimeFrame(e.target.value)}
                    />
                </label>
                <label>
                    Call Price
                    <input
                        type="text"
                        value={callPrice}
                        onChange={(e) => setCallPrice(e.target.value)}
                    />
                </label>
                <label>
                    Current Price
                    <input
                        type="text"
                        value={currentPrice}
                        onChange={(e) => setCurrentPrice(e.target.value)}
                    />
                </label>
                </div>
                <label>
                    Equity Status
                    <select
                        value={equityStatus}
                        onChange={(e) => setEquityStatus(e.target.value)}>
                        <option value="">Select Status</option>
                        <option value="OPEN">Open</option>
                        <option value="OPEN (Buy More)">Open (Buy More)</option>
                        <option value="CLOSED">Closed</option>
                        <option value="BOOKLOSS">Book Loss</option>
                        <option value="BOOKPROFIT">Book Profit</option>
                        <option value="WATCH">Watch List</option>
                    </select>
                </label>
                <div className="form-row">
                <label>
                    Fundamental Reasons
                    <input
                        type="text"
                        value={fundamental}
                        onChange={(e) => setFundamental(e.target.value)}
                    />
                </label>
                <label>
                    Technical Reasons
                    <input
                        type="text"
                        value={technical}
                        onChange={(e) => setTechnical(e.target.value)}
                    />
                </label>
                <label>
                    Conclusion
                    <input
                        type="text"
                        value={conclusion}
                        onChange={(e) => setConclusion(e.target.value)}
                    /> 
                </label>
                </div>
                <div className="modal-buttons">
                    <button className="save-button" onClick={handleSave}>Save</button>
                    <button className="close-button" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default EquityWynEditStockModal;