import React, { useEffect, useState } from 'react';
import './Monitor.css';
import ExpandedOrderBookModal from './ExpandedOrderBookModal';
import OTPModal from './OTPModal';
import StartStopAlgoModal from './StartStopAlgoModal';
import { useNavigate } from 'react-router-dom';

import config from '../config/config';

const Monitor = () => {

    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const [jobData, setJobData] = useState([]);
    const [popupMessage, setPopupMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [selectedOrderBook, setSelectedOrderBook] = useState(null);
    const [selectedUserDetails, setSelectedUserDetails] = useState(null);
    const [showOrderBookModal, setShowOrderBookModal] = useState(false);
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [showStartStopModal, setShowStartStopModal] = useState(false);

    const handleDhoniCheck = async (info, newValue) => { 
      try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('No token found, please login');
          }
          await fetch( `${config.API_URL}/update-strategy-status`, {
              method: 'POST',
              headers: { 
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
              },
              body: JSON.stringify({ strategy: 'GAVASKAR', scrip_name: 'NIFTY', user_id: info.id, isEnabled: newValue }),
          });
          setUserData(prevUserData =>
              prevUserData.map(user =>
                  user.id === info.id ? { ...user, isEnabled: newValue } : user
              )
          );
      } catch (error) {
          setPopupMessage('Failed to update Dhoni status');
          setShowPopup(true);
      }
      fetchUserData();
  };

  const handleGavasCheck = async (info, newValue) => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
        await fetch(`${config.API_URL}/update-strategy-status`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${token}`
             },
            body: JSON.stringify({strategy: 'GAVASKAR', scrip_name: 'BANKNIFTY', user_id: info.id, isEnabled: newValue }),
        });
        setUserData(prevUserData =>
            prevUserData.map(user =>
                user.id === info.id ? { ...user, isEnabled: newValue } : user
            )
        );
    } catch (error) {
        setPopupMessage('Failed to update Gavas status');
        setShowPopup(true);
    }
    fetchUserData();
};

    const handleLogs = () => { navigate ('/logs'); };
    const handleSubscription = () => { navigate ('/subscription'); };
    const handleStartStopAlgo = () => {setShowStartStopModal(true);};
    const handleCloseStartStopModal = () =>{
      setShowStartStopModal(false);
      fetchUserData();
      fetchJobData();
    }
    const handleCloseOrderBookModal = () => {setShowOrderBookModal(false);};
    const handleCloseOTPModal = () => {setShowOTPModal(false);};


    const handleOrderbook = async (user_name) => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
        const response = await fetch(`${config.API_URL}/orderbook`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ user_name: user_name })
        });
        const data = await response.json();
        setSelectedOrderBook(data);
        setShowOrderBookModal(true);
    } catch (error) {
        setPopupMessage('Failed to fetch order book');
        setShowPopup(true);
    }
    };

    const handleOTP = async (user_name) => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
        const response = await fetch(`${config.API_URL}/get-otp`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
         },
        body: JSON.stringify({ user_name: user_name })
        });
        const data = await response.json();
        console.log('data', data);
        setSelectedUserDetails(data);
        setShowOTPModal(true);
    }
    catch (error) {
        setPopupMessage(error.message);
        setShowPopup(true);
    }
    };

    const fetchUserData = async () => {
        try {
          const token = localStorage.getItem('token');
            if (!token) {
              throw new Error('No token found, please login');
            }
          const response = await fetch(`${config.API_URL}/getUsersInfo`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${token}`
            },
          });
          if (!response.ok) {
            throw new Error('Error fetching user data');
          }
          const data = await response.json();
          setUserData(data);
        } catch (error) {
          console.error('Error:', error);
        }
      };
    
      const fetchJobData = async () => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('No token found, please login');
          }
          const response = await fetch(`${config.API_URL}/getJobsInfo`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${token}`
            },
          });
          if (!response.ok) {
            throw new Error('Error fetching job data');
          }
          const data = await response.json();
          setJobData(data);
        } catch (error) {
          console.error('Error:', error);
        }
      };

    const handlePauseResume = async (user_name, isAlgoStopped) => {
      const newStatus = isAlgoStopped === "YES" ? 0 : 1;
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
        const response = await fetch(`${config.API_URL}/algo/status/update`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
           },
          body: JSON.stringify({ user_name: user_name, status: newStatus }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
    
        if (result.status) {
          setUserData(prevUserData =>
            prevUserData.map(user =>
              user.user_name === user_name ? { ...user, isAlgoStopped: newStatus === 0 ? 'NO' : 'YES' } : user
            )
          );
        } else {
          throw new Error(`Failed to ${newStatus === 0 ? 'stop' : 'start'} user`);
        }
      } catch (error) {
        setPopupMessage(error.message);
        setShowPopup(true);
      }
    
      fetchUserData();
      fetchJobData();
    };

    useEffect(() => {
      fetchUserData();
      fetchJobData();
    }, []);

    const handleRefresh = () => {
      fetchUserData();
      fetchJobData();
    };

    return (
        <div className="monitor-container">
        <div className='action-buttons-monitor'>
            <button className='refresh-button-monitor' onClick={handleRefresh}>Refresh</button>
            <button className='start-stop-algo-button-monitor' onClick={handleStartStopAlgo}>Start/Stop Algo</button>
            <button className='logs-button-monitor' onClick={handleLogs}>Logs</button>
            <button className='subscription-button-monitor' onClick={handleSubscription}>Subscription</button>
        </div>
        <br />
        <h2>Monitor</h2>
        <h4>User Info</h4>
        <div className="user-info-table">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>id</th>
                        <th>user_name</th>
                        <th>D_Gain</th>
                        <th>D_Loss</th>
                        <th>D_Net</th>
                        <th>G_Gain</th>
                        <th>G_Loss</th>
                        <th>G_Net</th>
                        <th>Total_PnL</th>
                        <th>CredChk</th>
                        <th>isGN</th>
                        <th>isGBN</th>
                        <th>AlgoStop</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {userData && userData.length > 0 ? (
                    userData.map(order => (
                    <tr key={order.id}>
                        <td>{order.id}</td>
                        <td>{order.user_name}</td>
                        <td>{order.DHONI_amount_gain}</td>
                        <td>{order.DHONI_amount_Loss}</td>
                        <td>{order.DHONI_amount_net}</td>
                        <td>{order.GAVAS_amount_gain}</td>
                        <td>{order.GAVAS_amount_Loss}</td>
                        <td>{order.GAVAS_amount_net}</td>
                        <td>{order.pnl}</td>
                        <td>{order.credChecked}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={order.isDhoniEnabled === 1}
                            onChange={() => handleDhoniCheck(order, order.isDhoniEnabled === 1 ? 0 : 1)}
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={order.isGavaskarEnabled === 1}
                            onChange={() => handleGavasCheck(order, order.isGavaskarEnabled === 1 ? 0 : 1)}
                          />
                        </td>
                        <td>{order.isAlgoStopped}</td>
                        <td className="monitor-table-action-buttons">
                            <button className='otp-button-monitor' onClick={() => handleOTP(order.user_name)}>OTP</button>
                            <button className="orderbook-button-monitor" onClick={() => handleOrderbook(order.user_name)}>Orderbook</button>
                            <button className={`start-stop-button-monitor ${order.isAlgoStopped === 'YES' ? 'start-button' : 'stop-button'}`} 
                                onClick={() => handlePauseResume(order.user_name, order.isAlgoStopped)}>
                                {order.isAlgoStopped === 'YES' ? 'Start' : 'Stop'}
                            </button>
                        </td>
                    </tr>
                    ))
                ) : (
                    <tr>
                    <td colSpan="5">No monitor data found</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
        <br/>
        <h4>Job Info</h4>
        <div className="job-info-table">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>id</th>      
                        <th>last_updated_datetime</th>
                        <th>name</th>
                    </tr>
                </thead>
                <tbody>
                    {jobData && jobData.length > 0 ? (
                    jobData.map(order => (
                        <tr key={order.id}>
                        <td>{order.id}</td>
                        <td>{order.last_updated_datetime}</td>
                        <td>{order.name}</td>
                        </tr>
                    ))
                    ) : (
                    <tr>
                        <td colSpan="5">No monitor data found</td>
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
        {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>{popupMessage}</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}

      {showOrderBookModal && (
        <ExpandedOrderBookModal
          orderBook={selectedOrderBook}
          onClose={handleCloseOrderBookModal}
        />
      )}
      {showOTPModal && (
        <OTPModal
          userDetails={selectedUserDetails}
          onClose={handleCloseOTPModal}
        />
      )}

      {showStartStopModal && (
        <StartStopAlgoModal
          onClose={handleCloseStartStopModal}
        />
      )}
        </div>
    );
};

export default Monitor;