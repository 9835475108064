import React from 'react';
import './MessageModal.css';

const MessageModal = ({ message, onClose }) => {
  return (
    <div className="message-modal-overlay">
      <div className="message-modal-content">
        <p dangerouslySetInnerHTML={{ __html: message }}></p>
        <div className="button-container">
          <button onClick={onClose}>Okay</button>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;