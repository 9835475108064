import React, { useState, useEffect } from 'react';
import './SettingsModal.css';
import { toast } from 'react-toastify';

import config from '../config/config';

function SettingsModal({isAdmin, userName, onClose, onSave }) {

  // const [brokers, setBrokers] = useState([]);

  const [userSettings, setUserSettings] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [userClientId, setUserClientId] = useState('');
  const [userClientPassword, setUserClientPassword] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [authKey, setAuthKey] = useState('');
  const [vendorCode, setVendorCode] = useState('');
  const [imei, setImei] = useState('');
  const [showVendorCode, setShowVendorCode] = useState(true);
  const [showImei, setShowImei] = useState(true);

  const fetchUserSettings = async () => {
    try {
      const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
      const response = await fetch(`${config.API_URL}/get-user-info-settings`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching user settings');
      }
      const data = await response.json();
      setUserSettings(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchBrokers = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found, please login');
      }
      const response = await fetch(`${config.API_URL}/get-brokers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching brokers');
      }
      // const data = await response.json();
      // setBrokers(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchBrokers();
    fetchUserSettings();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      const user = userSettings.find((u) => u.user_name === selectedUser);
      setUserClientId(user?.user_client_id || '');
      setUserClientPassword(user?.user_client_password || '');
      setApiKey(user?.api_key || '');
      setApiSecret(user?.api_secret || '');
      setAuthKey(user?.auth_key || '');
      setVendorCode(user?.vendor_code || '');
      setImei(user?.imei || '');
      setShowVendorCode(user?.vendor_code !== null);
      setShowImei(user?.imei !== null);
    }
  }, [selectedUser, userSettings]);

  useEffect(() => {
    if (!isAdmin) {
      setSelectedUser(userName);
    }
  }, [isAdmin, userName]);

  const handleSave = () => {
    const updatedSettings = {
      user_name: selectedUser,
      user_client_id: userClientId,
      user_client_password: userClientPassword,
      api_key: apiKey,
      api_secret: apiSecret,
      auth_key: authKey,
      vendor_code: vendorCode,
      imei: imei,
    };

    const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found, please login');
      }
    fetch(`${config.API_URL}/update-user-client-creds`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(updatedSettings),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error updating user settings');
        }
        return response.json();
      })
      .then(data => {
        toast.success('Successfully Updated');
        onClose();
      })
      .catch((error) => {
        console.error('Error:', error);
        toast.error('Failed to Update');
      });

    onSave(updatedSettings);
  };

  return (
    <div className="settings-modal">
      <div className="settings-modal-content">
        <h2>Broker Settings</h2>
        { isAdmin && <label>
          User Name:
          <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
            <option value="">Select User</option>
            {userSettings.map((user) => (
              <option key={user.user_name} value={user.user_name}>
                {user.user_name}
              </option>
            ))}
          </select>
        </label>}
        { !isAdmin && <label>
          User Name: {userName}
        </label>}
        <label>
          User Client ID:
          <input type="text" value={userClientId} onChange={(e) => setUserClientId(e.target.value)} />
        </label>
        <label>
          User Client Password:
          <input type="text" value={userClientPassword} onChange={(e) => setUserClientPassword(e.target.value)} />
        </label>
        <label>
          API Secret:
          <input type="text" value={apiSecret} onChange={(e) => setApiSecret(e.target.value)} />
        </label>
        <label>
          Auth Key:
          <input type="text" value={authKey} onChange={(e) => setAuthKey(e.target.value)} />
        </label>

        <label>
          API Key:
          <input type="text" value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
        </label>
        
        {showVendorCode && (
          <label>
            Vendor Code:
            <input type="text" value={vendorCode} onChange={(e) => setVendorCode(e.target.value)} />
          </label>
        )}
        {showImei && (
          <label>
            IMEI:
            <input type="text" value={imei} onChange={(e) => setImei(e.target.value)} />
          </label>
        )}
        
        <div className="settings-modal-buttons">
          <button className="save-button-settings-modal" onClick={handleSave}>Save</button>
          <button className="close-button-settings-modal" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default SettingsModal;