import React from 'react';
import './OTPModal.css';

const OTPModal = ({ userDetails, onClose }) => {

    if (!userDetails) {
        return null;
    }

  return (
    <div className="otp-modal">
        <div className="modal-content">
            <h2>OTP</h2>
            <table>
                <tbody>
                    <tr>
                        <td><strong>User Client ID:</strong></td>
                        <td>{userDetails.user_client_id}</td>
                    </tr>
                    <tr>
                        <td><strong>User Client Password:</strong></td>
                        <td>{userDetails.user_client_password}</td>
                    </tr>
                    <tr>
                        <td><strong>OTP:</strong></td>
                        <td>
                            <input 
                                type="text" 
                                value={userDetails.otp} 
                                disabled 
                                style={{ border: "none", backgroundColor: "transparent", color: "inherit", textAlign: "center" }} 
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <button onClick={onClose}>Close</button>
        </div>
    </div>
  );
};

export default OTPModal;