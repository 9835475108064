import React, { useEffect, useState } from 'react';
import './Registration.css';
import logo from '../logo.png';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import config from '../config/config';

function Registration() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');   
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [strategy, setStrategy] = useState('');
    const [strategies, setStrategies] = useState([]);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(null);
    const [isNumberAvailable, setIsNumberAvailable] = useState(null);
    const navigate = useNavigate();

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleUsernameChange = (e) => {
        const newUsername = e.target.value;
        setUsername(newUsername);
        if (newUsername) {
            checkUsernameAvailability(newUsername);
        } else {
            setIsUsernameAvailable(null);
        }
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        const newPhoneNumber = event.target.value;
        setPhoneNumber(newPhoneNumber);
        if (newPhoneNumber) {
            checkPhoneNumberAvailability(newPhoneNumber);
        } else {
            setIsNumberAvailable(null);
        }
    };


    const handleStrategyChange = (selectedStrategyId) => {
        if (strategy.includes(selectedStrategyId)) {
          setStrategy(strategy.filter((id) => id !== selectedStrategyId));
        } else {
          setStrategy([...strategy, selectedStrategyId]);
        }
    };      

    const checkUsernameAvailability = async (username) => {
        try {
            const response = await fetch(`${config.API_URL}/exists/username/${username}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error('Error checking username availability');
            }
    
            const data = await response.json();
            if (data.status === 'success') {
                setIsUsernameAvailable(true);
            } else if (data.status === 'failure') {
                setIsUsernameAvailable(false); // Username is not available
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            console.error('Error:', error);
            setIsUsernameAvailable(false); // Consider it unavailable in case of an error
        }
    };    
    
    const checkPhoneNumberAvailability = async (phoneNumber) => {
        try {
            const response = await fetch(`${config.API_URL}/exists/phonenumber/${phoneNumber}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }); 

            if (!response.ok) {
                throw new Error('Error checking phone number availability');
            }

            const data = await response.json();
            
            // Check the status in the response
            if (data.status === 'success') {
                setIsNumberAvailable(true); // Phone number is available
            } else if (data.status === 'failure') {
                setIsNumberAvailable(false); // Phone number is not available
            } else {
                throw new Error('Unexpected response status');
            }
        } catch (error) {
            console.error('Error:', error);
            setIsNumberAvailable(false); // Consider it unavailable in case of an error
        }
    };

    useEffect(() => {
        fetch(`${config.API_URL}/algoprofile`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (!response.ok) {
                    console.log('Error:', response.err);
                    return response.json().then(err => { throw err; });
                }
                return response.json();
            })
            .then(data => {
                console.log('Profile Response:', data);
                setStrategies(data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const handleRegistration = (event) => {
        event.preventDefault();

        const registrationData = {
            first_name: firstName,
            last_name: lastName,
            user_name: username,
            user_password: password,
            email_address: email,
            phone_number: phoneNumber,
            profiles: strategy
        };

        fetch(`${config.API_URL}/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(registrationData)
        })
            .then(response => {
                if (!response.ok) {
                    console.log('Error:', response.err);
                    return response.json().then(err => { throw err; });
                }
                return response.json();
            })
            .then(data => {
                console.log('Registration Response:', data);
                toast.success('Registration Successful');
                navigate('/login');
            })
            .catch((error) => {
                console.error('Error:', error);
                toast.error('Registration Failed');
            });
    };

    return (
        <div className="registration-container">
            <img src={logo} className="App-logo" alt="logo" />
            <h2>Registration</h2>
            <form onSubmit={handleRegistration}>
                <div className="form-group">

                    <input
                        type="text"
                        value={firstName}
                        placeholder='Enter your first name'
                        onChange={handleFirstNameChange}
                        required
                    />
                </div>
                <div className="form-group">
         
                    <input
                        type="text"
                        value={lastName}
                        placeholder='Enter your last name'
                        onChange={handleLastNameChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        value={username}
                        placeholder="Enter your username (min 5 char)"
                        onChange={handleUsernameChange}
                        required
                        style={{
                            color: username.length >= 5
                                ? (isUsernameAvailable === true ? 'green' : isUsernameAvailable === false ? 'red' : 'white')
                                : 'white',
                        }}
                    />
                </div>
                <div className="form-group">
                 
                    <input
                        type="password"
                        placeholder='Enter your password'
                        value={password}
                        onChange={handlePasswordChange}
                        required
                    />
                </div>
                <div className="form-group">
           
                    <input
                        type="password"
                        placeholder='Confirm your password'
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                    />
                </div>
                <div className="form-group">
       
                    <input
                        type="email"
                        placeholder='Enter your email'
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="tel"
                        placeholder='Enter your phone number'
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        required
                        maxLength={10}
                        style={{
                            color: phoneNumber.length >= 10
                                ? (isNumberAvailable === true ? 'green' : isNumberAvailable === false ? 'red' : 'white')
                                : 'white',
                        }}
                    />
                </div>
                <div className="form-group">
                    <label>
                        <span style={{ alignContent: 'left', display: 'flex', gap: '10px' }}>
                        Select Strategies
                        </span>
                    </label>
                    <div className="form-group-radio">
                        {strategies.map((strategyItem) => (
                        <div key={strategyItem.id} className="form-group-radio-item">
                            <input
                            type="checkbox"
                            name="strategy"
                            value={strategyItem.id}
                            checked={strategy.includes(strategyItem.id)} // Check if strategyItem.id is in the array
                            onChange={() => handleStrategyChange(strategyItem.id)} // Handle adding/removing from the array
                            />
                            <label className="form-group-radio-label">{strategyItem.profile_name}</label>
                        </div>
                        ))}
                    </div>
                </div>

                <button type="submit">Register</button>
            </form>
            <ToastContainer />
        </div>
    );
}

export default Registration;
