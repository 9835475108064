import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './ModifyItemModal.css';
import MessageModal from './MessageModal';

import config from '../config/config';

const ModifyItemModal = ({ isOpen, onClose, orders, fieldName, fieldLabel, apiEndpoint }) => {
  const [idNum, setIdNum] = useState('');
  const [fieldValue, setFieldValue] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({ ALL: true });
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      color: '#f0f0f0',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      color: '#f0f0f0',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#f0f0f0',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#444',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#f0f0f0',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#666' : state.isFocused ? '#555' : '#333',
      color: '#f0f0f0',
    }),
  };

  useEffect(() => {
    if (isOpen) {
      setIdNum('');
      setFieldValue('');
      setUsers([]);
      setSelectedUsers({ ALL: true });
    }
  }, [isOpen]);

  useEffect(() => {
    if (idNum) {
      // Filter orders where id_num matches the selected idNum
      const filteredOrders = orders.filter(order => order.id_num === idNum);

      if (filteredOrders.length > 0) {
        setFieldValue(filteredOrders[0][fieldName]);

        // Deduplicate users
        const uniqueUsers = filteredOrders
          .map(order => ({ value: order.user_id, label: order.user_name }))
          .filter((user, index, self) =>
            index === self.findIndex(u => u.value === user.value)
          );
        setUsers(uniqueUsers);
        setSelectedUsers({ ALL: true });
      } else {
        setFieldValue('');
        setUsers([]);
        setSelectedUsers({ ALL: true });
      }
    }
  }, [idNum, orders, fieldName]);

  const handleIdNumChange = (selectedOption) => {
    setIdNum(selectedOption ? selectedOption.value : '');
  };

  const handleUserChange = (selectedOptions) => {
    const allUsersSelected = selectedOptions.length === users.length;
    const newSelectedUsers = allUsersSelected
      ? { ALL: true }
      : selectedOptions.reduce((acc, option) => {
          acc[option.value] = option.label;
          return acc;
        }, {});
    setSelectedUsers(newSelectedUsers);
  };

  const handleSubmit = () => {
    const userIds = selectedUsers.ALL
      ? 'ALL'
      : Object.keys(selectedUsers).join(', ');

    fetch(`${config.API_URL}/${apiEndpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id_num: idNum,
        [fieldName]: fieldValue,
        user_id_list: userIds,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(errorText);
        }
        return response.json();
      })
      .then((data) => {
        setPopupMessage(`${data}`);
        setShowPopup(true);
      })
      .catch((err) => {
        setPopupMessage(`Failed to modify ${fieldLabel}: ${err.message}`);
        setShowPopup(true);
      });
  };

  const idNumOptions = [...new Set(orders.map(order => order.id_num))].map(id => ({
    value: id,
    label: id,
  }));

  const displaySelectedUsers = selectedUsers.ALL
    ? users
    : Object.keys(selectedUsers).map(userId => ({
        value: parseInt(userId),
        label: selectedUsers[userId],
      }));

  return isOpen ? (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Modify {fieldLabel}</h2>
        <div>
          <label>ID Number:</label>
          <Select
            options={idNumOptions}
            value={idNumOptions.find(option => option.value === idNum)}
            onChange={handleIdNumChange}
            styles={customSelectStyles}
            isClearable
          />
        </div>
        {idNum && (
          <>
            <div>
              <label>{fieldLabel}:</label>
              <input
                type="text"
                value={fieldValue}
                onChange={(e) => setFieldValue(e.target.value)}
              />
            </div>
            <div>
              <label>Select User:</label>
              <Select
                isMulti
                options={users}
                value={displaySelectedUsers}
                onChange={handleUserChange}
                styles={customSelectStyles}
              />
            </div>
          </>
        )}
        <div className="modal-buttons">
          <button onClick={handleSubmit}>Submit</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
      {showPopup && <MessageModal message={popupMessage} onClose={() => setShowPopup(false)} />}
    </div>
  ) : null;
};

export default ModifyItemModal;