import React from 'react';
import './ExpandedOrderBookModal.css';

function ExpandedOrderBookModal({ orderBook, onClose }) {
  return (
    <div className="order-book-modal">
      <div className="orderbook-modal-content">
        <h2>Order Book</h2>
        <table className="order-book-table">
          <thead>
            <tr>
              <th>Scrip Name</th>
              <th>Type</th>
              <th>Price</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {orderBook && orderBook.length > 0 ? (
              orderBook.map(order => (
                <tr key={order.id}>
                  <td>{order.scrip}</td>
                  <td>{order.type}</td>
                  <td>{order.price}</td>
                  <td>{order.time}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No order book details found</td>
              </tr>
            )}
          </tbody>
        </table>
        <div class="button-container">
          <button class="orderbook-modal-content" onClick={onClose}>Close</button>
        </div>

      </div>
    </div>
  );
}

export default ExpandedOrderBookModal;
