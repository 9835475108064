import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import './Orders.css';
import ExpandOrderModal from './ExpandOrderModal';
import ExpandedOrderBookModal from './ExpandedOrderBookModal';
import SettingsModal from './SettingsModal';
import ModifyItemModal from './ModifyItemModal';
import ExitWSModal from './ExitWSModal';
import ConfirmationModal from './ConfirmationModal';
import BrokerSettingsModal from './BrokerSettingsModal';

import config from '../config/config';

function Orders() {
  const location = useLocation();
  const { userId, userName } = location.state || {};
  const navigate = useNavigate();
  const width = window.innerWidth;

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = String(date.getUTCFullYear()).slice(-2);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  function formatToHHMM(dateString) {
    const date = new Date(dateString);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}


  const [fromDate, setFromDate] = useState(getTodayDate());
  const [toDate, setToDate] = useState(getTodayDate());
  const [scripName] = useState('ALL');
  const [statusFilter, setStatusFilter] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersError, setOrdersError] = useState(null);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrderExpand, setSelectedOrderExpand] = useState(null);
  const [isProdOrdersOnly, setIsProdOrdersOnly] = useState(true);
  const [selectedUsernames, setSelectedUsernames] = useState([]);
  const [selectedStrategies, setSelectedStrategies] = useState([]);
  const [selectedScrips, setSelectedScrips] = useState([]);
  const [totalPnl, setTotalPnl] = useState(0);
  const [selectedOrderBook, setSelectedOrderBook] = useState(null);
  const [showOrderBookModal, setShowOrderBookModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showBrokerSettingsModal, setShowBrokerSettingsModal] = useState(false);
  const [showModifySLModal, setShowModifySLModal] = useState(false);
  const [showModifyTPModal, setShowModifyTPModal] = useState(false);
  const [showExitWSModal, setShowExitWSModal] = useState(false);
  const [algoStatus, setAlgoStatus] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  // const [selectedOrders, setSelectedOrders] = useState([]); //For Order Checkbox

  useEffect(() => {
    fetchIsAdmin(userName);
    fetchAlgoStatus();
  });

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      color: '#f0f0f0',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      color: '#f0f0f0',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#f0f0f0',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#444',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#f0f0f0',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#666' : state.isFocused ? '#555' : '#333',
      color: '#f0f0f0',
    }),
  };
  
  const handleFromDateChange = (event) => setFromDate(event.target.value);
  const handleToDateChange = (event) => setToDate(event.target.value);
  const handleScripChange = (selectedOptions) => setSelectedScrips(selectedOptions.map(option => option.value));
  const handleStatusFilterChange = (selectedOptions) => setStatusFilter(selectedOptions.map(option => option.value));
  const handleUserChange = (selectedOptions) => setSelectedUsernames(selectedOptions.map(option => option.value));
  const handleStrategyChange = (selectedOptions) => setSelectedStrategies(selectedOptions.map(option => option.value));
  const handleProdOrdersOnlyChange = (e) => setIsProdOrdersOnly(e.target.checked);
  const handleLogout = () => navigate('/');
  const handleSettings = () => setShowSettingsModal(true);
  // const handleBSettings = () => setShowBrokerSettingsModal(true);
  const handleModifySL = () => setShowModifySLModal(true);
  const handleModifyTP = () => setShowModifyTPModal(true);
  const handleExitWS = () => setShowExitWSModal(true);
  const handleMonitor = () => {
    window.open('/monitor', '_blank',{state: {token: localStorage.getItem('token')}});
  };

  const handleStartStop = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirm = () => {
    updateAlgoStatus();
    setIsConfirmModalOpen(false);
    fetchAlgoStatus();
  };

  const handleCancel = () => {
    setIsConfirmModalOpen(false);
  };

  // const handleSelectOrder = (orderId) => {
  //   setSelectedOrders(prevSelectedOrders =>
  //     prevSelectedOrders.includes(orderId)
  //       ? prevSelectedOrders.filter(id => id !== orderId)
  //       : [...prevSelectedOrders, orderId]
  //   );
  // };

  // const handleSelectAll = (event) => {
  //   if (event.target.checked) {
  //     setSelectedOrders(filteredOrders.map(order => order.id));
  //   } else { 
  //     setSelectedOrders([]);
  //   }
  // };
  // const handleRunStrategy = () => navigate('/jobStatus', {});;

  const uniqueScrips = useMemo(() => [
    { value: 'BANKNIFTY', label: 'BANKNIFTY' },
    { value: 'NIFTY', label: 'NIFTY' },
  ], []);

  const uniqueStatuses = useMemo(() => Array.from(new Set(orders.map(order => order.status))).map(status => ({
    value: status,
    label: status,
  })), [orders]);

  const uniqueUsernames = useMemo(() => Array.from(new Set(orders.map(order => order.user_name))).map(username => ({
    value: username,
    label: username,
  })), [orders]);

  const uniqueStrategies = useMemo(() => Array.from(new Set(orders.map(order => order.strategy_name))).map(strategy => ({
    value: strategy,
    label: strategy,
  })), [orders]);

  const filteredOrders = useMemo(() => {
    if (!Array.isArray(orders)) return [];
    
    const filtered = orders.filter(order => {
      const isProdOrder = !isProdOrdersOnly || order.buy_order_id !== "0";
      const isStatusSelected = statusFilter.length === 0 || statusFilter.includes(order.status);
      const isUserSelected = selectedUsernames.length === 0 || selectedUsernames.includes(order.user_name);
      const isStrategySelected = selectedStrategies.length === 0 || selectedStrategies.includes(order.strategy_name);
      
      // Check if the order's scrip_name starts with "BANKNIFTY" or "NIFTY"
      const isScripSelected = selectedScrips.length === 0 || selectedScrips.some(scrip => 
        order.scrip_name.startsWith(scrip)
      );
      
      return isProdOrder && isUserSelected && isStrategySelected && isStatusSelected && isScripSelected;
    });
    
    return filtered.sort((a, b) => a.id - b.id);
  }, [orders, isProdOrdersOnly, selectedUsernames, selectedStrategies, statusFilter, selectedScrips]);
  
  useEffect(() => {
    // Calculate total PNL based on filtered orders
    const total = filteredOrders.reduce((sum, order) => sum + (parseFloat(order.pnl) || 0), 0);
    setTotalPnl(total);
  }, [filteredOrders]);

  // const isUsernamePresent = useMemo(() => Array.isArray(orders) && orders.some(order => order.user_name && order.user_name.trim() !== ""), [orders]);

  const fetchIsAdmin = async (username) => {
    try {
      const response = await fetch(`${config.API_URL}/is-admin/${username}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching job status');
      }
      const data = await response.json();
      setIsUserAdmin(data.status);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchOrders = useCallback(async (jsonFetchOrders) => {
    try {
      const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
      const response = await fetch(`${config.API_URL}/weborders/filter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(jsonFetchOrders),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'No orders found');
      }

      const data = await response.json();
      setOrders(data);
      setOrdersError(null);
    } catch (error) {
      console.error('Error:', error);
      setOrdersError('No orders found');
      setOrders([]);
    }
  }, []);

  const fetchAlgoStatus = async () => {
    try {
      const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
      const response = await fetch(`${config.API_URL}/algo/status/username/${userName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
      });
      if (!response.ok) {
        throw new Error('Error fetching job status');
      }
      const data = await response.json();
      setAlgoStatus(data.status);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateAlgoStatus = async () => {
    const newStatus = algoStatus === 'RUNNING' ? 1 : 0;
    const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found, please login');
      }
    const response = await fetch(`${config.API_URL}/algo/status/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({
        user_name: userName,
        status: newStatus
      })
    });
    if (!response.ok) {
      throw new Error('Error updating algo status');
    }
    // const data = await response.json();
    setIsConfirmModalOpen(false);
  };

  const exitOrder = async (orderId) => {
    const jsonExitOrder = {
      order_id: orderId
    };
    const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found, please login');
      }
    const response = await fetch(`${config.API_URL}/exit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(jsonExitOrder)
    });
    if (!response.ok) {
      return response.json().then(err => { throw err; });
    }
    return await response.json();
  };

  // Periodical refresh is disabled for now
  // useEffect(() => {
  //   if(isUserAdmin===1) return;
  //   const fetchOrdersPeriodically = async () => {
  //     console.log('statusFilter', statusFilter);
  //     await fetchOrders({
  //       user_name: userName,
  //       scrip_name: scripName,
  //       order_status: statusFilter,
  //       from_date: fromDate,
  //       to_date: toDate,
  //     });
  //   };

  //   fetchOrdersPeriodically();
  //   const interval = setInterval(fetchOrdersPeriodically, 5000);

  //   return () => clearInterval(interval);
  // }, [isUserAdmin, userName, scripName, fromDate, toDate, statusFilter, fetchOrders]);


  const handleSearch = (event) => {
    event.preventDefault();
    const jsonFetchOrders = {
      user_name: userName,
      scrip_name: scripName,
      order_status: statusFilter,
      from_date: fromDate,
      to_date: toDate
    };
    fetchOrders(jsonFetchOrders);
  };

  const handleExitOrder = (orderId) => {
    if (window.confirm('Are you sure you want to exit this order?')) {
      exitOrder(orderId)
        .then(data => {
          setPopupMessage('Exit Successfully');
          setShowPopup(true);
          const jsonFetchOrders = {
            user_name: userName,
            scrip_name: scripName,
            order_status: statusFilter,
            from_date: fromDate,
            to_date: toDate
          };
          fetchOrders(jsonFetchOrders);
        })
        .catch(err => {
          setPopupMessage('Failed to exit order');
          setShowPopup(true);
        });
    }
  };

  const handleModifyOrder = () => {
    const { id, last_stop_loss, target_price } = selectedOrder;
    const jsonModifyOrders = {
      id,
      last_stop_loss,
      target_price
    };

    if (window.confirm('Are you sure you want to save the changes?')) {
      const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
      fetch(`${config.API_URL}/modify-stop-loss`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(jsonModifyOrders)
      })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => { throw err; });
        }
        return response.json();
      })
      .then(data => {
        setPopupMessage('Modified Successfully');
        setShowPopup(true);
        setShowModifyModal(false);
        const jsonFetchOrders = {
          user_name: userName,
          scrip_name: scripName,
          order_status: statusFilter,
          from_date: fromDate,
          to_date: toDate
        };
        fetchOrders(jsonFetchOrders);
      })
      .catch(err => {
        setPopupMessage('Failed to modify order');
        setShowPopup(true);
      });
    }
  };

  const handleExpandOrder = (order) => setSelectedOrderExpand(order);
  const handleCloseOrderBookModal = () => setShowOrderBookModal(false);
  const handleClose = () => setSelectedOrderExpand(null);

  const handleOrderbook = async (id) => {
    try {
      const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
      const response = await fetch(`${config.API_URL}/orderbook`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ order_id: id })
      });
      const data = await response.json();
      setSelectedOrderBook(data);
      setShowOrderBookModal(true);
    } catch (error) {
      setPopupMessage('Failed to fetch order book');
      setShowPopup(true);
    }
  };

  const shouldShowActions = (status) => {
    const statusesToExclude = ['COMPLETE', 'CANCELED', 'CANCELLED', 'STOPLOSS_COMPLETE', 'SQUARRED_OFF'];
    return !statusesToExclude.includes(status);
  };

  const handleModifyButtonClick = (order) => {
    setSelectedOrder(order);
    setShowModifyModal(true);
  };

  const actionText = algoStatus === 'RUNNING' ? 'Stop' : 'Start';

  return (
    <div className="page-container">
      <div className="username-container">
        {userName && <p> Welcome, {userName}!</p>}
        <button className='logout-button' onClick={handleLogout}>Logout</button>
      </div>
      <div className="actions-container">
        <button className='back-button-orders' onClick={() => navigate(-1)}>Back</button>
        {<button className="settings-button" onClick={handleSettings}>BrokerSettings</button>}
        {/* <button className="settings-button" onClick={handleBSettings}>B.Settings</button> */}
        <button className={`start-stop-button ${algoStatus === 'RUNNING' ? 'stop' : 'start'}`}
          onClick={handleStartStop}
        >
          {algoStatus === 'RUNNING' ? 'Stop Algo' : 'Start Algo'}
        </button>
        {isUserAdmin === 1 && (
          <>
            <button className='monitor-button' onClick={handleMonitor}>Monitor</button>
            <button className="modify-sl-wholesale-button" onClick={handleModifySL}>Mf.SL</button>
            <button className='modifyitarget-wholesale-button' onClick={handleModifyTP}>Mf.TP</button>
            <button className="exit-ws-button" onClick={handleExitWS}>ExitWS</button>
          </>
        )}
        {/* <button className="run-strategy-button" onClick={handleRunStrategy}>Run Strategy</button> */}
      </div>
      <h2 style={{marginTop: '30px'}}>Orders</h2>
      <div className="controls">
        <div className="date-selection">
          <label>
            From Date:
            <input type="date" value={fromDate} onChange={handleFromDateChange} />
          </label>
        </div>
        <div className="date-selection">
        <label>
            To Date:
            <input type="date" value={toDate} onChange={handleToDateChange} />
          </label>
        </div>
        <div className="dropdown-selection">
          <label>
            Select Scrip
            <Select
              isMulti
              options={uniqueScrips}
              onChange={handleScripChange}
              styles={customSelectStyles}
            />
          </label>
        </div>
        <div className="dropdown-selection-filter">
          <label>
            Status Filter
            <Select
              isMulti
              options={uniqueStatuses}
              onChange={handleStatusFilterChange}
              styles={customSelectStyles}
            />
          </label>
        </div>
        {isUserAdmin === 1 && (
          <div className="dropdown-selection-filter">
            <label>
              Strategy
              <Select
                isMulti
                options={uniqueStrategies}
                onChange={handleStrategyChange}
                styles={customSelectStyles}
              />
            </label>
          </div>
        )}

        {isUserAdmin === 1 && (
          <div className="dropdown-selection-filter">
            <label>
              Select Users
              <Select
                isMulti
                options={uniqueUsernames}
                onChange={handleUserChange}
                styles={customSelectStyles}
              />
            </label>
          </div>
        )}
        <div className="checkbox-selection">
          <label>Prod Orders Only</label>
          <input type="checkbox" checked={isProdOrdersOnly} onChange={handleProdOrdersOnlyChange} />
        </div>
        <button className="search-button" onClick={handleSearch}>Search</button>
      </div>

      <div className="total-pnl-container">
        <h4>Total PnL: {totalPnl}</h4>
      </div>

      {ordersError ? (
        <p>{ordersError}</p>
      ) : (
        <div className="orders-table">
          {filteredOrders.length === 0 ? (
            <p>No orders found</p>
          ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                {width>578 && isUserAdmin === 1 && (<th>Id_Num</th>)}
                {width>578 && (<th>Od. ID</th>)}
                <th>Date Time</th>
                <th>Scrip Name</th>
                {isUserAdmin === 1 && <th>Username</th>}
                {width>578 && (<th>Qty.</th>)}
                {width>578 && (<th>Buy P.</th>)}
                {width>578 && (<th>Sell P.</th>)}
                {width>578 && (<th>SL P.</th>)}
                {width>578 && (<th>Target</th>)}
                {width>578 && (<th>TR</th>)}
                {width>578 && (<th>TRT</th>)}
                {width>578 && (<th>H.Price</th>)}
                {width>578 && (<th>HP T.</th>)}
                {width>578 && (<th>L.Price</th>)}
                {width>578 && (<th>LP T.</th>)}
                <th>P/L</th>
                {width>578 && (<th>Stgy</th>)}
                {width>578 && (<th>Status</th>)}
                {isUserAdmin ===1 && (<th>Action</th>)}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(filteredOrders) && filteredOrders.map(order => (
                <tr key={order.id}>
                  {width>578 && isUserAdmin === 1 && (<td>{order.id_num !== null ? order.id_num : 'N/A'}</td>)}
                  {width>578 && (<td>{order.id}</td>)}
                  <td>{formatDate(order.order_buy_datetime)}</td>
                  <td>
                    <a
                      href={`/order-details/${order.id}`}
                      onClick={(e) => {
                        e.preventDefault(); // If you still want to prevent the default navigation
                        handleExpandOrder(order);
                      }}
                      style={{ textDecoration: 'underline', color: '#2880eb', cursor: 'pointer' }}
                    >
                      {order.scrip_name}
                      {order.buy_order_id === "0" ? " (TEST)" : ""}
                    </a>
                  </td>
                  {isUserAdmin === 1 && <td>{order.user_name}</td>}
                  {width>578 && (<td>{order.buy_qty}</td>)}
                  {width>578 && (<td>{order.buy_price}</td>)}
                  {width>578 && (<td>{order.sell_price !== null ? order.sell_price : 'N/A'}</td>)}
                  {width>578 && (<td>{order.last_stop_loss !== null ? order.last_stop_loss : 'N/A'}</td>)}
                  {width>578 && (<td>{order.target_price}</td>)}
                  {width>578 && (<td>{order.target_revised}</td>)}
                  {width>578 && (<td>{order.target_revised_trail}</td>)}
                  {width>578 && (<td>{order.high_price !== null ? order.high_price : 'N/A'}</td>)}
                  {width>578 && (<td>{order.high_price_time !== null ? formatToHHMM(order.high_datetime) : 'N/A'}</td>)}
                  {width>578 && (<td>{order.low_price !== null ? order.low_price : 'N/A'}</td>)}
                  {width>578 && (<td>{order.low_price_time !== null ? formatToHHMM(order.low_datetime) : 'N/A'}</td>)}
                  <td style={{ color: order.pnl === null ? 'white' : order.pnl < 0 ? 'red' : order.pnl > 0 ? 'green' : 'white' }}>
                    {order.pnl !== null ? order.pnl : 'N/A'}
                  </td>
                  {width>578 && (<td>{order.strategy_name !== null ? order.strategy_name.substring(0, 3) : 'N/A'}</td>)}
                  {width>578 && (<td>{order.status.substring(0, 4)}</td>)}
                  { isUserAdmin === 1 && (<td className="action-buttons">
                  <button className='orderbook-button' onClick={() => handleOrderbook(order.id)}>OB</button>
                    { shouldShowActions(order.status) && (
                      <>
                        <button className="modify-button" onClick={() => handleModifyButtonClick(order)}>Mod</button>
                        <button className="exit-button" onClick={() => handleExitOrder(order.id)}>Ex</button>
                      </>
                    )}
                  </td>)}
                </tr>
              ))}
            </tbody>
          </table>)}
        </div>
      )}

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>{popupMessage}</p>
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}


      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        message={`Are you sure you want to ${actionText.toUpperCase()} the Algo for today? It will reset to the default state next day.`}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />

      {showSettingsModal && (
        <SettingsModal
          isAdmin={isUserAdmin === 1}
          userName={userName}
          onClose={() => setShowSettingsModal(false)}
          onSave={(settings) => {
            // Handle settings save logic here
            console.log('Input for Settings:', settings);
            setShowSettingsModal(false);
          }}
        />
      )}

      {showBrokerSettingsModal && (
        <BrokerSettingsModal
          isAdmin={isUserAdmin === 1}
          userId={userId}
          userName={userName}
          onClose={() => setShowBrokerSettingsModal(false)}
          onSave={(settings) => {
            // Handle settings save logic here
            console.log('Input for Settings:', settings);
            setShowBrokerSettingsModal(false);
          }}
        />
      )}

      {showModifySLModal && (
        <ModifyItemModal 
          isOpen={showModifySLModal} 
          onClose={() => setShowModifySLModal(false)}
          orders={orders} 
          fieldName="last_stop_loss" 
          fieldLabel="Last Stop Loss" 
          apiEndpoint="modify-stop-loss-all" 
        />
      )}

      {showModifyTPModal && (
        <ModifyItemModal 
          isOpen={showModifyTPModal} 
          onClose={() => setShowModifyTPModal(false)}
          orders={orders} 
          fieldName="target_price" 
          fieldLabel="Target Price" 
          apiEndpoint="modify-target-all" 
        />
      )}

      {showExitWSModal && (
        <ExitWSModal 
        isOpen={showExitWSModal} 
        onClose={() => setShowExitWSModal(false)} 
        orders={orders} 
      />
      )}

      {selectedOrderExpand && (
        <ExpandOrderModal order={selectedOrderExpand} onClose={handleClose} />
      )}
      {showOrderBookModal && (
        <ExpandedOrderBookModal
          orderBook={selectedOrderBook}
          onClose={handleCloseOrderBookModal}
        />
      )}

      {showModifyModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Modify Order</h2>
            <label>
              Stop Loss:
              <input
                type="text"
                value={selectedOrder.last_stop_loss}
                onChange={(e) => setSelectedOrder({ ...selectedOrder, last_stop_loss: e.target.value })}
              />
            </label>
            <label>
              Target Price:
              <input
                type="text"
                value={selectedOrder.target_price}
                onChange={(e) => setSelectedOrder({ ...selectedOrder, target_price: e.target.value })}
              />
            </label>
            <div className="modal-buttons">
              <button className="save-button" onClick={handleModifyOrder}>Save</button>
              <button className="cancel-button" onClick={() => setShowModifyModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Orders;