import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './EquityWynStockDetails.css';

import config from '../../config/config';

function EquityWynStockDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const { stock_id } = location.state;
    const [stockDetails, setStockDetails] = useState(null);
    const [chartImage, setChartImage] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found, please login');
        }
        fetch(`${config.API_URL}/get-equity-stock-details/${stock_id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error fetching stock details');
                }
                return response.json();
            })
            .then(data => {
                setStockDetails(data);
                fetchChartImage(data.image_url);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [stock_id]);

    const fetchChartImage = (imageUrl) => {
        fetch(`${config.API_URL}/${imageUrl}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }) 
            .then(response => {
                if (!response.ok) {
                    throw new Error('Error fetching stock details');
                }
                return response.blob();
            })
            .then(blob => {
                const objectURL = URL.createObjectURL(blob);
                setChartImage(objectURL);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleBack = () => {
        navigate(-1);
    };
    

    if (!stockDetails) {
        return <div style={{display: 'flex', justifyContent: 'center', height: '100vh', marginTop: '50px'}}><h1>Loading...</h1></div>;
    }

    return (
        <div className="equity-wyn-container">
            <div className='action-buttons-equitywyn-stock-details'>
                <button className="back-button-equitywyn" onClick={handleBack}>Back</button>
            </div>
            <div className="equity-wyn-content">
                <div className="sidebar">
                    <div className="logo-container">
                        <img src="../logo.png" alt="Logo" className="logo" />
                    </div>
                    <table className="stock-details-table" style={{marginTop: '20px'}}>
                        <tbody>
                            <thead style={{color: '#ff705a'}}>{stockDetails.name != null ? stockDetails.name : 'N/A'}</thead>
                            <tr>
                                <td>Status</td>
                                <td style={{color: '#ff705a'}}>{stockDetails.equity_status}</td>
                            </tr>
                            <tr>
                                <td>CMP</td>
                                <td style={{color: '#ff705a'}}>र{stockDetails.current_price != null ? stockDetails.current_price : 'N/A'}</td>
                            </tr>
                            <tr>
                                <td>Buy Range (Rs.)</td>
                                <td style={{color: '#ff705a'}}>{stockDetails.buy_range != null ? stockDetails.buy_range : 'N/A'}</td>
                            </tr>
                            <tr>
                                <td>Target</td>
                                <td style={{color: '#ff705a'}}>र{stockDetails.target != null ? stockDetails.target : 'N/A'}</td>
                            </tr>
                            <tr>
                                <td>SL</td>
                                <td style={{color: '#ff705a'}}>र{stockDetails.stop_loss != null ? stockDetails.stop_loss : 'N/A'}</td>
                            </tr>
                            <tr>
                                <td>Horizon</td>
                                <td style={{color: '#ff705a'}}>{stockDetails.horizon != null ? stockDetails.horizon : 'N/A'}</td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <hr/>
                    <table className="stock-details-table">
                        <tbody>
                            {stockDetails && (
                                <>
                                    <thead style={{color: '#ff705a'}}>Report Card</thead>
                                    <tr>
                                      <td>
                                        {typeof stockDetails.pe_ratio === 'number' 
                                            ? 'PE' 
                                            : stockDetails.pe_ratio.split(':')[0]}
                                        </td>
                                        <td style={{ color: '#ff705a' }}>
                                        {typeof stockDetails.pe_ratio === 'number' 
                                            ? `र ${stockDetails.pe_ratio}` 
                                            : stockDetails.pe_ratio.split(':')[1]}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        {typeof stockDetails.eps === 'number' 
                                            ? 'EPS' 
                                            : stockDetails.eps.split(':')[0]}
                                        </td>
                                        <td style={{ color: '#ff705a' }}>
                                        {typeof stockDetails.eps === 'number' 
                                            ? `र ${stockDetails.eps}` 
                                            : stockDetails.eps.split(':')[1]}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                            {typeof stockDetails.market_cap === 'number' 
                                            ? 'Market Cap. (Cr)' 
                                            : stockDetails.market_cap.split(':')[0]}
                                        </td>
                                        <td style={{ color: '#ff705a' }}>
                                            {typeof stockDetails.market_cap === 'number' 
                                            ? `र ${(stockDetails.market_cap / 100000000).toFixed(2)} Cr.` 
                                            : stockDetails.market_cap.split(':')[1]}
                                      </td>
                                    </tr>
                                    <tr>
                                        <td>Sector</td>
                                        <td style={{color: '#ff705a'}}>{stockDetails.sector != null ? stockDetails.sector : 'N/A'}</td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="main-content">
                <div className="writeup-container">
                    <h3 className="header"><u>{stockDetails.name != null ? stockDetails.name : 'N/A'} (CMP: र{stockDetails.current_price != null ? stockDetails.current_price : 'N/A'})</u></h3>

                    {stockDetails.about && (
                        <>
                            <h5>About</h5>
                            <p>{stockDetails.about}</p>
                        </>
                    )}

                    {stockDetails.fundamental && stockDetails.fundamental.length > 0 && (
                        <>
                            <hr/>
                            <h5>Fundamental Reasons</h5>
                            <p><ul>
                                {stockDetails.fundamental.map((funda, index) => (
                                    <li key={index}>{funda}</li>
                                ))}
                            </ul></p>
                            
                        </>
                    )}

                    {stockDetails.technical && stockDetails.technical.length > 0 && (
                        <>
                            <hr/>
                            <h5>Technical Reasons</h5>
                            <p><ul>
                                {stockDetails.technical.map((tech, index) => (
                                    <li key={index}>{tech}</li>
                                ))}
                            </ul></p>
                            
                        </>
                    )}

                    {stockDetails.conclusion && (
                        <>
                            <hr/>
                            <h5>Conclusion</h5>
                            <p>{stockDetails.conclusion}</p>
                        </>
                    )}

                    {chartImage && <img style={{ width: '100%' , maxHeight: '500px', maxWidth: '700px' }} src={chartImage} alt="Chart" />}
                </div>

                </div>
            </div>
        </div>
    );
}

export default EquityWynStockDetails;