import React from 'react';

function Footer() {
    return (
        <footer className="equitywyn-footer" style={{ 
            padding: '10px', 
            backgroundColor: '#10151b', 
            color: '#f0f0f0', 
            width: '100vw',
            height: '50px',
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            textAlign: 'center',
            margin: '0'
        }}>
            <p>
                <a href='/disclaimer'>Disclaimer</a> | &copy; 2024 Wynby Technologies. All Rights Reserved.
            </p>
        </footer>
    );
}

export default Footer;