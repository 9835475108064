import React, { useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import logo from "../logo.png";
import Navbar from "./Navbar";
import "./Home.css";
import Footer from "./Footer";

function Home() {
  const homeRef = useRef(null);
  const strategiesRef = useRef(null);
  const faqRef = useRef(null);
  const termsOfUseRef = useRef(null);
  const aboutUsRef = useRef(null);

  const navigate = useNavigate();
  const width = window.innerWidth;
  
  const handleNavigate = (event, section) => {
    event.preventDefault();
    switch (section) {
      case "home":
        homeRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "strategies":
        strategiesRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "faq":
        faqRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "termsofuse":
        termsOfUseRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "aboutus":
        aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <div className="Home-container">
      { width>578 && (<Navbar onNavigate={handleNavigate} homepage="algowyn" />)}
      {width<=578 && (<div className="action-buttons-home">
        <button className='login-button' onClick={() => navigate('/login')}>Login</button>
        <button className='register-button' onClick={() => navigate('/registration')}>Sign Up</button>
      </div>)}

      <section className="home-section-aw" ref={homeRef} id="home">
        <HomeContent />
      </section>

      <section
        className="strategies-section-aw"
        ref={strategiesRef}
        id="strategies"
      >
        <h2>Our Star Strategies</h2>
        <Strategies />
      </section>

      <section className="faq-section-aw" ref={faqRef} id="faq">
        <h2>FAQ</h2>
        <Faq />
      </section>

      <section className="aboutus-section-aw" ref={aboutUsRef} id="aboutus">
        <h2>About Us</h2>
        <AboutUs />
      </section>
      <Footer />
    </div>
  );
}

function HomeContent() {
  return (
    <div className="home-content">
      <img src={logo} className="App-logo" alt="logo" />
      <h1 style={{ textAlign: "center" }}>
        We are <span style={{ color: "#ff593f" }}>Wynby</span>
      </h1>
      <p>
      <span style={{ color: "#ff593f" }}>Wynby </span> stands apart with its dedication to providing reliable, user-centric solutions. Whether it's through the human expertise of Equity Wyn or the automated precision of AlgoWyn, we are committed to enabling financial success for our users.
      </p>
      <p>
        Together, <span style={{ color: "#ff593f" }}>EquityWyn</span> and <span style={{ color: "#ff593f" }}>AlgoWyn </span> represent the perfect synergy of human insight and technological innovation. Join us on this journey to transform how you invest and trade.
      </p>
    </div>
  );
}

function Strategies() {
  const strategiesMap = [
    [
      "EquityWyn",
      "EquityWyn provides weekly training sessions and stock picks made by our AI backed algorithm and further curated by us. We also provide a responsive website for easier call tracking",
        "7+ winners over 12 weeks*",
      "Rs.2000/month",
    ],
    [
      "EquityWyn Premium",
      "EquityWyn Premium provides all features of EquityWyn plus advice on portfolio rebalancing and FNO Intraday and BTST recommendations",
        "7+ winners over 12 weeks*",
      "Rs.5000/month",
    ],
    [
      "Dhoni",
      "Dhoni strategy works on an inside candle setup specifically tailored for trading with NIFTY. A trailing stop loss is used to maximize profits and achieve high returns.",
      "14% over 3 weeks*",
      "Rs.1000/month",
    ],
    [
      "Gavaskar",
      "The Gavaskar strategy is an opening candle breakout setup specifically tailored for trading with BANKNIFTY. A trailing stop loss is used to maximize profits and achieve high returns.",
      "17% over 1 week*",
      "Rs.1000/month",
    ],
    ["Bundle", "DHONI + GAVASKAR", "-", "Rs.1750/month"],
  ];

  return (
    <div className="strategies">
      <table className="strategies-table">
        <thead>
          <tr>
            <th>Strategy</th>
            <th>Description</th>
            <th>Forward Test</th>
            <th>Pricing</th>
          </tr>
        </thead>
        <tbody>
          {strategiesMap.map((row, index) => (
            <tr key={index}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <p className="disclaimer">
        *Disclaimer: Investment values can go up or down based on market conditions, with no guarantee of returns.
      </p>
    </div>
  );
}

function Faq() {
  const [openQuestion, setOpenQuestion] = useState(null);

  const handleQuestionClick = (questionIndex) => {
    setOpenQuestion(openQuestion === questionIndex ? null : questionIndex);
  };

  return (
    <div className="faq">
      {faqData.map((item, index) => (
        <div className="faq-item" key={index}>
          <div className="faq-question" onClick={() => handleQuestionClick(index)}>
            {item.question}
          </div>
          {openQuestion === index && (
            <div className="faq-answer">{item.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
}

const faqData = [
  { question: "What is EquityWyn?", answer: "EquityWyn provides you a platform to earn passive income by providing you training on fundamentals/technicals of stock picking. We also recommend you stocks by providing stop loss and targets on our website for easy call tracking and we have our own Telegram group." },
  
  { question: "How do I subscribe to EquityWyn?", answer: "We have 2 packages EquityWyn( 2000/month) and EquityWyn Premium (5000/month). By subscribing you get weekly training sessions plus stock recommendations."},
  
  { question: "How are stocks picked in EquityWyn?", answer: "We have developed an AI backed algorithm for stock selection. We further manually analyse and curate our stock picks."},

  { question: "How has been the performance of EquityWyn?", answer: "Since our inception 2 months back, we have had 7+ winners each garnering atleast 20% profits. Earlier we were giving free calls on our telegram group MoneyPlant since the last 5 years."},

  { question: "What is AlgoWyn?", answer: "AlgoWyn facilitates Algo based trading on FNO derivatives (NIFTY and BANKNIFTY). AlgoWyn gives you accurate entry and exit points with proper risk to reward management. AlgoWyn does not require you to be a technical expert in trading and also solves issues of over trading/emotional bias which leads to expensive mistakes." },
 
  { question: "What is the minimum fund I need to have to run Order(s)?", answer: "The minimum fund/margin requirement is Rs.15,000 per lot, per strategy." },

  { question: "How do I use AlgoWyn?", answer: "Once you register on AlgoWyn, you get an Android app and web application. You will receive a set of credentials for login. Post login you will be on the Orders page where you can monitor and modify/exit your trades." },

  { question: "I don’t want to trade for the remainder of the day or a week, how do I stop algo from running?", answer: "There is a 'Pause/Resume' button within the AlgoWyn app/site, which you can use to stop/resume your algo trading." },

  { question: "Does AlgoWyn take positional trades?", answer: "We do not take positional trades; all our Algo strategies are intra-day index trades only." },

  { question: "Is back tested result available?", answer: "Backtested data can be made available upon request. We also have a chatbot deployed built on top of chatGPT which you can use to query on the performance of the strategies and build confidence in using our strategies. The backtested results are available at Chat Bot." },

  { question: "How to generate API Key in Shoonya?", answer: "Log in to your Shoonya PRISM portal. On the top right, dropdown under your Login display name > Click on “API Key”. Upon signing the agreement, you will reach “Generate an API key” - you will get - Vendor Code, IMEI, and API Key. Along with the above details, share your User ID and Password for backend integration with AlgoWyn. See FAQ on 'How to generate Shoonya TOTP authentication/ aphanumeric code?' Refer to this video for more details." },

  { question: "How to generate Shoonya TOTP authentication/ alphanumeric code?", answer: "For this activity, you need Google Authenticator downloaded on your mobile phone (even on iPhone). Login to Shoonya - 'https://trade.shoonya.com'. Click on the Login button on the top right corner. Under that click on 'Security'. Under Security click on 'TOTP'. A QR Code will be displayed. Below the QR Code image, another field with encrypted passcode having alphanumeric characters will be generated, copy that and share it with us. Refer to this video for more details." },

  { question: "How to generate API Key in Zerodha?", answer: "Go to Kite Connect developer Signup and create a Kite Connect developer account. Log in to your Kite Connect developer account. (signup using your email id which is linked to your Kite demat account). Click on Create New App. Select 'Connect' (2000 Credits for 30 days). Give a name to the app under 'App Name'. Enter your Zerodha Client ID. Enter 'https://kite.zerodha.com' in both Redirect URL and Postback URL. Give a description for the app and last click on 'Create'. A pop-up will open, write 'I understand' and click on OK. API Key and Secret API Key is generated (both are Alphanumeric). Along with the above keys, share your User Id, Password for backend integration with AlgoWyn. See the FAQ on 'How to generate Zerodha TOTP authentication/aphanumeric code?' Refer to this video for more details." },

  { question: "How to generate Zerodha TOTP authentication/alphanumeric code?", answer: "This Login is through www.kite.zerodha.com. Click on the Client ID. Click on My profile / Settings. Click on Password & Security. Click on Enable External TOTP. Enter the OTP received on the email and click on Verify. Through the authenticator you use, scan and get the 6 digit code. Enter the TOTP and kite login password. Below the QR Code image, click on 'Can't scan? Copy the code' - copy the alphanumeric characters and share it with us. Refer to this tutorial for more details." }

];

function AboutUs() {
  return (
    <div className="about-us">
      <p>
        <span style={{ color: "#ff593f" }}>AlgoWyn</span> AlgoWyn was conceived and developed to address the challenges of managing intra-day trading while juggling a full-time job or business. Armed with technical expertise, we set out to create a solution to simplify and optimize stock trading: an intelligent algo trading system designed to make smart, informed decisions, leading to winning trades.
      </p>
      <p>
        <br/> Our founders, Amita Keni, Nagendra Shenoy, Prashant Baluchgi and Prasad Kailaje, have extensive experience in stock trading, and entrepreneurship. Their collaboration resulted in the development and launch of <span style={{ color: '#ff593f' }}>Wynby</span>.</p>
    </div>
  );
}

export default Home;