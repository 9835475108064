import React, { useEffect, useState } from "react";
import './BrokerSettingsModal.css';
import config from "../config/config";

function BrokerSettingsModal({isAdmin, userId, userName, onClose, onSave }) {
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState("");
    const [userSettings, setUserSettings] = useState([]);
    const [prefilledDetails, setPrefilledDetails] = useState({});
    const [apiKey, setApiKey] = useState("");
    const [apiSecret, setApiSecret] = useState("");

    console.log('Api Key:', apiKey);
    console.log('Api Secret:', apiSecret);

    const handleClientChange = (event) => {
        const selectedId = parseInt(event.target.value, 10);
        setSelectedClient(selectedId);

        // Prefill details if matching user settings are found
        const matchedSettings = userSettings.find(
            (setting) => setting.client_id === selectedId && setting.user_name === userName
        );
        if (matchedSettings) {
            setPrefilledDetails(matchedSettings);
        } else {
            setPrefilledDetails({});
        }
    };

    const fetchClients = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("No token found, please login");
            }
            const response = await fetch(`${config.API_URL}/get-clients`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Error fetching clients");
            }
            const data = await response.json();
            setClients(data);
            console.log("Clients:", data);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const fetchUserSettings = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("No token found, please login");
            }
            const response = await fetch(`${config.API_URL}/get-user-info-settings`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error("Error fetching user settings");
            }
            const data = await response.json();
            setUserSettings(data);

            // Automatically select and prefill settings for the logged-in user
            const matchedSetting = data.find(
                (setting) => setting.user_name === userName
            );
            if (matchedSetting) {
                setSelectedClient(matchedSetting.client_id);
                setPrefilledDetails(matchedSetting);
                console.log("Prefilled Details:", prefilledDetails.apiKey);
                setApiSecret(prefilledDetails.api_secret);
            }
            console.log("User Settings:", data);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        fetchClients();
        fetchUserSettings();
    });

    const handleSubmit = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("No token found, please login");
            }

            const payload = {
                id: prefilledDetails.id || null,
                client_id: selectedClient || null,
                api_key: prefilledDetails.api_key || null,
                api_secret: prefilledDetails.api_secret || null,
            };

            if (selectedClient === 'Shoonya') {
                payload.vendor_code = prefilledDetails.vendor_code || null;
                payload.imei = prefilledDetails.imei || null;
            }

            const response = await fetch(`${config.API_URL}/update-user-settings`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Failed to save broker settings");
            }

            const result = await response.json();
            console.log("Settings saved successfully:", result);
            alert("Settings saved successfully");
            onClose();
        } catch (error) {
            console.error("Error submitting settings:", error);
            alert("Error saving settings. Please try again.");
        }
    };
    const handleZerodhaLogin = () => {
        setApiKey(prefilledDetails.api_key);
        console.log("Login Button Clickec", apiKey)
        kiteLogin();
    };

    const kiteLogin = () => {
        window.open(`https://kite.zerodha.com/connect/login?api_key=${apiKey}`, '_blank');
    };

    const handleShoonyaLogin = () => {
        window.open('/monitor', '_blank');
    };

    const handleKotakLogin = () => {
        window.open('/monitor', '_blank');
    };

    const handleAngelOneLogin = () => {
        window.open('/monitor', '_blank');
    };

    const handleUpstoxLogin = () => {
        window.open('/monitor', '_blank');
    };

    return (
        <div className="broker-settings">
            <div className="broker-settings-content">
                <h1 className="broker-settings__header">Broker Settings</h1>

            <div className="broker-login-buttons">
                {selectedClient === 1 && <button className="broker-login-button" onClick={handleZerodhaLogin}>Zerodha Login</button>}
                {selectedClient === 2 && <button className="broker-login-button" onClick={handleShoonyaLogin}>Shoonya Login</button>}
                {selectedClient === 3 && <button className="broker-login-button" onClick={handleKotakLogin}>Kotak Login</button>}
                {selectedClient === 4 && <button className="broker-login-button" onClick={handleAngelOneLogin}>AngelOne Login</button>}
                {selectedClient === 5 && <button className="broker-login-button" onClick={handleUpstoxLogin}>Upstox Login</button>}
            </div>
            <br/>
            <div className="broker-settings__client-select">
                <label htmlFor="clientSelect" className="broker-settings__label">Select Client:</label>
                <select
                    id="clientSelect"
                    className="broker-settings__dropdown"
                    value={selectedClient}
                    onChange={handleClientChange}
                >
                    <option value="">-- Select a Broker --</option>
                    {clients.map((client) => (
                        <option key={client.id} value={client.id}>
                            {client.name}
                        </option>
                    ))}
                </select>
            </div>
            <br />   
            {/* Zerodha Instructions */}
            {selectedClient === 1 && (
                <div className="broker-settings__details broker-settings__details--zerodha">
                    <ol className="broker-settings__instructions" type="1">
                        <li>
                            IMPORTANT - Please make sure Zerodha TOTP is set up. 
                            Find instructions to set up TOTP here: 
                            <a 
                                href="https://support.zerodha.com/category/your-zerodha-account/login-credentials/login-credentials-of-trading-platforms/articles/time-based-otp-setup" 
                                className="broker-settings__link"
                                target="_blank" 
                                rel="noopener noreferrer"
                            >
                                Zerodha TOTP Setup
                            </a>
                        </li>
                        <li>
                            Go to developers.kite.trade and create a new app.
                            <ol className="broker-settings__nested-instructions" type="a">
                                <li>
                                    Choose Type as Connect. Enter any App Name as you like and Enter your Zerodha Client ID.
                                </li>
                                <li>
                                    When creating the app, Copy and paste this URL as your Redirect URL:
                                    <div className="copiable-link">
                                        <input
                                            type="text"
                                            value={`https://api.wynbytech.com/updatetoken?client_id=${userId}`}
                                            readOnly
                                            className="copiable-link__input"
                                        />
                                        <button
                                            type="button"
                                            onClick={() => {
                                                navigator.clipboard.writeText(`https://api.wynbytech.com/updatetoken?client_id=${userId}`);
                                                alert("Link copied to clipboard!");
                                            }}
                                            className="copiable-link__button"
                                        >
                                            Copy
                                        </button>
                                    </div>
                                </li>
                            </ol>
                        </li>
                        <li>
                            Copy the API key and API secret and paste them below, then click Add.
                        </li>
                    </ol>
                    <div className="broker-settings__input-group">
                        <input
                            type="text"
                            className="broker-settings__input"
                            placeholder="Enter API Key"
                            value={prefilledDetails.api_key || ""}
                            onChange={(e) =>
                                setPrefilledDetails({
                                    ...prefilledDetails,
                                    api_key: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="broker-settings__input-group">
                        <input
                            type="text"
                            className="broker-settings__input"
                            placeholder="Enter API Secret"
                            value={prefilledDetails.api_secret || ""}
                            onChange={(e) =>
                                setPrefilledDetails({
                                    ...prefilledDetails,
                                    api_secret: e.target.value,
                                })
                            }
                        />
                    </div>
                    <br />
                    <div className="broker-settings-buttons">
                        <button 
                            type="button" 
                            className="broker-settings__button broker-settings__button--save" 
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                        <button className="broker-settings__button broker-settings__button--cancell" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            )}
    
            {/* Shoonya Finvasia Instructions */}
            {selectedClient === 2 && (
                <div className="broker-settings__details broker-settings__details--finvasia">
                    <ol className="broker-settings__instructions" type="1">
                        <li>
                            IMPORTANT - Please make sure Authenticator TOTP is set up. 
                            Find instructions to set up TOTP here: 
                            <a 
                                href="https://www.youtube.com/watch?v=zLQAn4E61u0" 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="broker-settings__link"
                            >
                                Finvasia TOTP Setup
                            </a>
                        </li>
                        <li>Enter Finvasia Client Code below:</li>
                    </ol>
                    <div className="broker-settings__input-group">
                        <input
                            type="text"
                            className="broker-settings__input"
                            placeholder="Enter Finvasia Client Code"
                            value={prefilledDetails.api_key || ""}
                            onChange={(e) => {
                                const newApiKey = e.target.value;
                                setPrefilledDetails({
                                    ...prefilledDetails,
                                    api_key: newApiKey,
                                });
                                setApiKey(newApiKey);
                            }}
                        />
                    </div>
                    <br />
                    <div className="broker-settings-buttons">
                        <button 
                            type="button" 
                            className="broker-settings__button broker-settings__button--save" 
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                        <button className="broker-settings__button broker-settings__button--cancell" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            )}
    
            {/* Kotak Instructions */}
            {selectedClient === 3 && (
                <div className="broker-settings__details broker-settings__details--kotak">
                    <ol className="broker-settings__instructions" type="1">
                        <li>API Activation:</li>
                        <ol className="broker-settings__nested-instructions" type="1">
                            <li>
                                Please go to 
                                <a 
                                    href="https://www.kotaksecurities.com/" 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="broker-settings__link"
                                >
                                    Kotak Securities
                                </a>.
                            </li>
                            <li>Click on Products</li>
                            <li>Under Products, click on Neo TradeAPI</li>
                            <li>Enter your Client ID and Click Register for Neo TradeAPI</li>
                            <li>Fill the form with the required details and click on Submit.</li>
                            <li>
                                You will receive your Kotak Neo TradeAPI credentials on your registered email in 24 hours.
                            </li>
                        </ol>
                        <li>Copy the received credentials and paste them in respective fields below:</li>
                    </ol>
                    <div className="broker-settings__input-group">
                        <input
                            type="text"
                            className="broker-settings__input"
                            placeholder="Enter Consumer Key"
                            value={prefilledDetails.api_key || ""}
                            onChange={(e) =>
                                setPrefilledDetails({
                                    ...prefilledDetails,
                                    api_key: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="broker-settings__input-group">
                        <input
                            type="text"
                            className="broker-settings__input"
                            placeholder="Enter Consumer Secret"
                            value={prefilledDetails.api_secret || ""}
                            onChange={(e) =>
                                setPrefilledDetails({
                                    ...prefilledDetails,
                                    api_secret: e.target.value,
                                })
                            }
                        />
                    </div>
                    <br />
                    <div className="broker-settings-buttons">
                        <button 
                            type="button" 
                            className="broker-settings__button broker-settings__button--save" 
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                        <button className="broker-settings__button broker-settings__button--cancell" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            )}

            {/* AngelOne Instructions */}
            {selectedClient === 4 && (
                <div className="broker-settings__details broker-settings__details--angelone">
                    <ol className="broker-settings__instructions" type="1">
                        <li>
                            Please go to 
                            <a 
                                href="https://smartapi.angelbroking.com" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="broker-settings__link"
                            >
                                smartapi.angelbroking.com
                            </a> 
                            and log in to your account.
                        </li>
                        <li>
                            Create a new app, and fill out the following details:
                            <ul className="broker-settings__list">
                                <li>App Name: Algowyn</li>
                                <li>Redirect URL'</li>
                                <div className="copiable-link">
                                    <input
                                        type="text"
                                        value={`https://api.wynbytech.com/updatetoken?client_id=${userId}`}
                                        readOnly
                                        className="copiable-link__input"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            navigator.clipboard.writeText(`https://api.wynbytech.com/updatetoken?client_id=${userId}`);
                                            alert("Link copied to clipboard!");
                                        }}
                                        className="copiable-link__button"
                                    >
                                        Copy
                                    </button>
                                </div>
                            </ul>
                        </li>
                        <li>Enter the API key of the generated app below.</li>
                    </ol>
                    <div className="broker-settings__input-group">
                        <input
                            type="text"
                            className="broker-settings__input"
                            placeholder="Enter API Key"
                            value={prefilledDetails.api_key || ""}
                            onChange={(e) =>
                                setPrefilledDetails({
                                    ...prefilledDetails,
                                    api_key: e.target.value,
                                })
                            }
                        />
                    </div>
                    <br />
                    <div className="broker-settings-buttons">
                        <button 
                            type="button" 
                            className="broker-settings__button broker-settings__button--save" 
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                        <button className="broker-settings__button broker-settings__button--cancell" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            )}

            {/* Upstox Instructions */}
            {selectedClient === 5 && (
                <div className="broker-settings__details broker-settings__details--upsox">
                    <ol className="broker-settings__instructions" type="1">
                        <li>
                            Please go to
                            <a 
                                href="https://upsox.com" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="broker-settings__link"
                            >
                                upsox.com
                            </a> 
                            and log in to your account.
                        </li>
                        <li>
                            Go to "My Account" section, choose the App tab, then click on New App
                            <ul className="broker-settings__list">                                
                                <li>App Name: Algowyn</li>                                
                                <li>Copy and paste this url as your Redirect URL</li>
                                <div className="copiable-link">
                                    <input
                                        type="text"
                                        value={`https://api.wynbytech.com/updatetoken?client_id=${userId}`}
                                        readOnly
                                        className="copiable-link__input"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            navigator.clipboard.writeText(`https://api.wynbytech.com/updatetoken?client_id=${userId}`);
                                            alert("Link copied to clipboard!");
                                        }}
                                        className="copiable-link__button"
                                    >
                                        Copy
                                    </button>
                                </div>
                            </ul>
                        </li>
                        <li>Enter the API key of the generated app below.</li>
                    </ol>
                    <div className="broker-settings__input-group">
                        <input
                            type="text"
                            className="broker-settings__input"
                            placeholder="Enter API Key"
                            value={prefilledDetails.api_key || ""}
                            onChange={(e) =>
                                setPrefilledDetails({
                                    ...prefilledDetails,
                                    api_key: e.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="broker-settings__input-group">
                        <input
                            type="text"
                            className="broker-settings__input"
                            placeholder="Enter API Secret"
                            value={prefilledDetails.api_secret || ""}
                            onChange={(e) =>
                                setPrefilledDetails({
                                    ...prefilledDetails,
                                    api_secret: e.target.value,
                                })
                            }
                        />
                    </div>
                    <br />
                    <div className="broker-settings-buttons">
                        <button 
                            type="button" 
                            className="broker-settings__button broker-settings__button--save" 
                            onClick={handleSubmit}
                        >
                            Save
                        </button>
                        <button className="broker-settings__button broker-settings__button--cancell" onClick={onClose}>Cancel</button>
                    </div>
                </div>
            )}
            </div>
        </div>
    );    
}

export default BrokerSettingsModal;