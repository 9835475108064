import React, { useState, useEffect } from "react";
import "./EquityWynEquities.css";
import { useLocation, useNavigate } from "react-router-dom";
import AddStockModal from "./AddStockModal";
import EquityWynEditStockModal from "./EquityWynEditStockModal";
import LockedCardModern from "./LockedCardModern";
import MessageModal from "../MessageModal";
import Footer from "../Footer";

import config from '../../config/config';

function EquityWynEquities() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user_id } = location.state || {};
    const { equityUser } = location.state || {};
    const [equities, setEquities] = useState([]);
    // const [sortOption, setSortOption] = useState('call_date');
    const sortOption = 'call_date';
    const [showAddStockModal, setShowAddStockModal] = useState(false);
    const [showEditStockModal, setShowEditStockModal] = useState(false);
    const [isUserAdmin, setIsUserAdmin] = useState(false);
    const [boughtStocks, setBoughtStocks] = useState([]);
    const [tickerCodes, setTickerCodes] = useState([]);
    const [stocksCurrentPrice, setStocksCurrentPrice] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [showSubscribeMessageModal, setShowSubscribeMessageModal] = useState(false);
    const paid = true;

  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);

  useEffect(() => {
    fetchIsAdmin(user_id);
    fetchBoughtStocks(user_id);
  }, [user_id]);

  useEffect(() => {
    const hasSeenMessage = sessionStorage.getItem("hasSeenMessage");
    if (!hasSeenMessage) {
      setShowMessageModal(true);
      sessionStorage.setItem("hasSeenMessage", "true");
    }
  }, []);

  const fetchIsAdmin = async (username) => {
    try {
      const response = await fetch(`${config.API_URL}/is-admin/${username}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Error fetching job status");
      }
      const data = await response.json();
      setIsUserAdmin(data.status);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchBoughtStocks = async (username) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found, please login");
      }
      const response = await fetch(
        `${config.API_URL}/get-bought-equity-stocks/${username}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Error fetching bought stocks");
      }
      const data = await response.json();
      setBoughtStocks(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No token found, please login");
    }
    fetch(`${config.API_URL}/get-equity-stocks`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error fetching equities");
        }
        return response.json();
      })
      .then((data) => {
        setEquities(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const sortEquities = (equities, sortOption) => {
    switch (sortOption) {
      case "stock_name":
        return equities.sort((a, b) =>
          a.stock_name.localeCompare(b.stock_name)
        );
      case "current_price":
        return equities.sort((a, b) => b.current_price - a.current_price);
      case "call_date":
        return equities.sort(
          (a, b) => new Date(b.call_date) - new Date(a.call_date)
        );
      case "pnl":
        return equities.sort((a, b) => b.pnl - a.pnl);
      default:
        return equities;
    }
  };

  useEffect(() => {
    if (equities && equities.length > 0) {
      const tickerCodes = equities.map((equity) => equity.ticker_code);
      setTickerCodes(tickerCodes);
    }
  }, [equities]);

  useEffect(() => {
    if (tickerCodes && tickerCodes.length > 0) {
      fetchStocksCMP(tickerCodes);
    }
  }, [tickerCodes]);

  const fetchStocksCMP = async (tickerCodes) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found, please login");
      }
      const response = await fetch(`${config.API_URL}/get-equity-stocks-cmp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ticker_codes: tickerCodes }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching stocks: ${response.statusText}`);
      }
      const data = await response.json();
      setStocksCurrentPrice(data);
    } catch (error) {
      console.error("Error in fetchStocksCMP:", error);
    }
  };

  const formatData = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = String(date.getUTCFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  const handleLogOut = () => {
    navigate("/");
  };

  const handleAddStock = () => {
    setShowAddStockModal(true);
  };

  const handleEditStock = () => {
    setShowEditStockModal(true);
  };

  const handleAllCalls = () => {
    navigate("/equitywyn/all-calls", { state: { user_id, isUserAdmin } });
  };

  const handleCardClick = (stock_id) => {
    if (paid) {
      navigate("/equitywyn/stock-details", { state: { stock_id } });
    }
  };

  const handleWatchList = () => {
    navigate("/equitywyn/watch-list", { state: { user_id, isUserAdmin } });
  };

  const updateBuyStatus = async (is_bought, stock_id) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No token found, please login");
      }
      const response = await fetch(`${config.API_URL}/update-equity-stock-status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          user_name: user_id,
          stock_id: stock_id,
          is_bought: is_bought,
        }),
      });
      if (!response.ok) {
        throw new Error("Error updating buy status");
      }
      const data = await response.json();
      console.log("Buy Status Updated:", data);
    } catch (error) {
      console.error("Error:", error);
    }
    fetchBoughtStocks(user_id);
  };

  if (!user_id) {
    return (
      <div>
        <h1>Not Authorised</h1>
      </div>
    );
  }

  if (!paid) {
    return (
      <div>
        <LockedCardModern />
      </div>
    );
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      {/* Header Section */}
      <header
        className="equitywyn-header"
        style={{ padding: "10px", color: "#f0f0f0" }}
      >
        <div className="username-container">
          {user_id && <p> Welcome, {user_id}!</p>}
          <button className="logout-button" onClick={handleLogOut}>
            Logout
          </button>
        </div>
        <div className="action-buttons-equitywyn-equities">
          <button
            className="back-button-equitywyn"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          {isUserAdmin === 1 && (
            <button className="add-stock-button" onClick={handleAddStock}>
              Add Stock
            </button>
          )}
          {isUserAdmin === 1 && (
            <button className="edit-stock-button" onClick={handleEditStock}>
              Edit Stock
            </button>
          )}
          {equityUser && (
            <button className="all-calls-button" onClick={handleAllCalls}>
              All Calls
            </button>
          )}
          {!equityUser && (
            <button
              className="all-calls-button"
              onClick={() => setShowSubscribeMessageModal(true)}
            >
              All Calls
            </button>
          )}
          {isUserAdmin === 1 && (
            <button className="watch-list-button" onClick={handleWatchList}>
              Watch List
            </button>
          )}
        </div>
      </header>

      {/* Main Content */}
      <main style={{ flexGrow: 1 }}>
        <h2
          className="equitywyn-title"
          style={{ marginTop: "5px", color: "#ff593f", fontWeight: "bold" }}
        >
          EquityWyn - Recommended Stocks
        </h2>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          <label
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "5px",
            }}
          >
            <input
              type="checkbox"
              checked={showAll}
              onChange={(e) => setShowAll(e.target.checked)}
            />
          </label>
          <span style={{ color: "#ff593f" }}>Show All Stocks.</span>
        </div>

        <div className="card-container">
          {sortEquities(equities, sortOption)
            .filter((equity) => equity.equity_status !== "WATCH")
            .filter(
              (equity) => showAll || new Date(equity.last_updated) >= tenDaysAgo
            )
            .map((equity, index) => {
              const boughtStock = boughtStocks.find(
                (stock) => stock.id === equity.id
              );
              const isBought = boughtStock
                ? boughtStock.is_bought === 1
                : false;
              const totalCount = boughtStock ? boughtStock.total_count : 0;
              const currentPrice =
                stocksCurrentPrice[equity.ticker_code] || "N/A";

              // Check if user is an equityUser
              const maskedStockName = !equityUser
                ? "XXXXXXX"
                : equity.stock_name;
              const maskedBuyRange = !equityUser ? "XXX" : equity.buy_range;
              const maskedCurrentPrice = !equityUser ? "XXX" : currentPrice;
              const maskedTargetPrice = !equityUser
                ? "XXX"
                : equity.target_price;
              const maskedStopLoss = !equityUser ? "XXX" : equity.stop_loss;

              return (
                <div
                  key={index}
                  className={`equity-card ${
                    equity.equity_status === "OPEN"
                      ? "open-card"
                      : equity.equity_status === "CLOSED"
                      ? "closed-card"
                      : equity.equity_status === "BOOKLOSS"
                      ? "loss-card"
                      : equity.equity_status === "BOOKPROFIT"
                      ? "profit-card"
                      : ""
                  }`}
                  onClick={() => {
                    if (!equityUser) {
                      setShowSubscribeMessageModal(true);
                    } else {
                      handleCardClick(equity.id);
                    }
                  }}
                >
                  <div>
                    {equity.equity_status === "BOOKPROFIT" ? (
                      <div>
                        {equity.equity_status} at Rs.{equity.sell_price} on{" "}
                        {formatData(equity.sell_date)}
                      </div>
                    ) : (
                      <div>{equity.equity_status}</div>
                    )}
                  </div>
                  <div className="equity-card-header">
                    <div>
                      <h3 className="stock-name">{maskedStockName.length > 15
                              ? maskedStockName.substring(0, 13) + ".."
                              : maskedStockName}</h3>
                      CMP: {maskedCurrentPrice}
                    </div>
                    <div className="buy-info">
                      <div className="buy-text">Rs.{maskedBuyRange}</div>
                      <div className="entity">Buy Range</div>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="info-grid">
                    <p>
                      <strong>{maskedTargetPrice}</strong> Target Price
                    </p>
                    <p>
                      <strong>{maskedStopLoss}</strong> Stop Loss
                    </p>
                    <p>
                      <strong>
                      <span
                        style={{
                          color: equity.equity_status === "BOOKPROFIT" ? "#ff593f" : "inherit",
                        }}
                      >
                        {equity.equity_status === "BOOKPROFIT"
                          ? `${(
                              ((equity.sell_price - equity.call_price) / equity.call_price) *
                              100
                            ).toFixed(2)}% Profit`
                          : `${(
                              ((equity.target_price - equity.call_price) / equity.call_price) *
                              100
                            ).toFixed(2)}%`}
                      </span>
                      </strong>
                      {equity.equity_status !== "BOOKPROFIT" && " Exp. Profit"}
                    </p>
                  </div>
                  Call Date: <b>{formatData(equity.call_date)}</b> &nbsp;
                  Horizon: <b>{equity.time_frame}</b>
                  <div className="line"></div>
                  {/* Separate checkbox section from card onClick */}
                  {equityUser && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                      onClick={(e) => e.stopPropagation()} // Ensure clicks in this section do not trigger the card click
                    >
                      <label
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          marginTop: "5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={isBought}
                          onChange={(e) => {
                            e.stopPropagation(); // Prevents the checkbox click from triggering handleCardClick
                            updateBuyStatus(e.target.checked, equity.id);
                          }}
                        />
                      </label>
                      Yes, I have bought this.
                    </div>
                  )}
                  {isBought && totalCount === 1
                    ? "You bought this stock."
                    : isBought && totalCount > 1
                    ? `You +${totalCount - 1} people bought this.`
                    : totalCount > 0 && !isBought
                    ? `${totalCount} people bought this.`
                    : null}
                </div>
              );
            })}
        </div>
        {showAddStockModal && (
          <AddStockModal onClose={() => setShowAddStockModal(false)} />
        )}
        {showEditStockModal && (
          <EquityWynEditStockModal
            onClose={() => setShowEditStockModal(false)}
          />
        )}
        {showMessageModal && (
          <MessageModal
            message={`SEBI Mandatory Guidelines<br /> <br/><strong>**Disclaimer</strong><br />No Tips ❌<br />No Advice ❌<br />No Recommendation ❌<br />This website doesn't provide tips, recommendations, or advice. All posts are for educational and learning purposes.`}
            onClose={() => setShowMessageModal(false)}
          />
        )}
        {showSubscribeMessageModal && (
          <MessageModal
            message={`Subscribe to receive daily updates on your stocks and strategies, and get full acces.`}
            onClose={() => setShowSubscribeMessageModal(false)}
          />
        )}
      </main>
      <Footer />
    </div>
  );
}

export default EquityWynEquities;
