import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable'; // Use react-select/creatable for typing and selecting
import './AddStockModal.css';

import config from '../../config/config';

function AddStockModal({ onClose }) {
    const [stockName, setStockName] = useState('');
    const [symbol, setSymbol] = useState('');
    const [buyRange, setBuyRange] = useState('');
    const [callPrice, setCallPrice] = useState('');
    const [currentPrice, setCurrentPrice] = useState('');
    const [callDate, setCallDate] = useState('');
    const [stopLoss, setStopLoss] = useState('');
    const [targetPrice, setTargetPrice] = useState('');
    const [timeFrame, setTimeFrame] = useState('');
    const [equityStatus, setEquityStatus] = useState('');
    const [fundamental, setFundamental] = useState('');
    const [technical, setTechnical] = useState('');
    const [conclusion, setConclusion] = useState('');
    const [companies, setCompanies] = useState([]);
    const [isSymbolDisabled, setIsSymbolDisabled] = useState(false); // New state to control symbol field disabling
    
    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const token = localStorage.getItem('token');
                if (!token) {
                throw new Error('No token found, please login');
                }
            const response = await fetch(`${config.API_URL}/get-companies`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
            });
            if (!response.ok) {
                throw new Error('Error fetching companies');
            }
            const data = await response.json();
            setCompanies(data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCompanyChange = (newValue, actionMeta) => {
        if (actionMeta.action === 'select-option') {
            setStockName(newValue.label); // Set stock name to selected company's name
            setSymbol(newValue.value);    // Set symbol to selected company's symbol
            setIsSymbolDisabled(true);    // Disable the symbol field when company is selected
        } else if (actionMeta.action === 'create-option') {
            setStockName(newValue.label); // Set stock name to typed company's name
            setSymbol('');                // Reset symbol for manual input
            setIsSymbolDisabled(false);   // Enable the symbol field for manual entry
        } else if (actionMeta.action === 'clear') {
            setStockName('');  // Reset the stock name
            setSymbol('');     // Reset the symbol
            setIsSymbolDisabled(false);  // Enable symbol field for manual entry
        }
    };

    const handleSave = async () => {
        if (!stockName || !symbol) {
            alert('Please enter a stock name and symbol');
            return;
        }
        try {
            const token = localStorage.getItem('token');
            if (!token) {
            throw new Error('No token found, please login');
            }
            const response = await fetch(`${config.API_URL}/add-equity-stock`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    call_date: callDate,
                    stock_name: stockName,
                    ticker_code: symbol,
                    buy_range: buyRange,
                    call_price: callPrice,
                    current_price: currentPrice,
                    stop_loss: stopLoss,
                    target_price: targetPrice,
                    time_frame: timeFrame,
                    equity_status: equityStatus,
                    fundamental: fundamental,
                    technical: technical,
                    conclusion: conclusion
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to save stock');
            }
            const data = await response.json();
            console.log('Save successful:', data);
            onClose(); // Close the modal after saving
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="add-stock-modal">
            <div className="modal-content">
                <h2>Add Stock</h2>
                <label>
                    Call Date
                    <input type="date" value={callDate} onChange={(e) => setCallDate(e.target.value)} />
                </label>
                Company Name
                <CreatableSelect
                    options={companies.map(company => ({ value: company.symbol, label: company.company }))}
                    onChange={handleCompanyChange}
                    placeholder="Select or Type Company"
                    isClearable
                    styles={{
                        control: (provided) => ({
                            ...provided,
                            width: '300px', // Set the width to 200px
                            backgroundColor: '#2b2b2b', // Dark background for the input
                            color: '#f0f0f0',
                            borderColor: '#444', // Border color
                            boxShadow: 'none',
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            color: '#b6b6b6', // Placeholder color
                        }),
                        input: (provided) => ({
                            ...provided,
                            color: '#f0f0f0', // Text color when typing (set to white)
                        }),
                        menu: (provided) => ({
                            ...provided,
                            backgroundColor: '#2b2b2b', // Dark background for dropdown
                            color: '#f0f0f0',
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            color: '#f0f0f0', // Text color in dropdown
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? '#444' : '#2b2b2b', // Background on hover
                            color: state.isFocused ? '#ffffff' : '#f0f0f0', // Text color on hover
                        }),
                        dropdownIndicator: (provided) => ({
                            ...provided,
                            color: '#ffffff', // Dropdown arrow color
                        }),
                        indicatorSeparator: (provided) => ({
                            ...provided,
                            backgroundColor: '#ffffff', // Separator color
                        }),
                        clearIndicator: (provided) => ({
                            ...provided,
                            color: '#ffffff',
                        }),
                        menuList: (provided) => ({
                            ...provided,
                            backgroundColor: '#2b2b2b', // Background for the options list
                        }),
                    }}
                />
                <label>
                    Symbol
                    <input type="text" value={symbol} onChange={(e) => setSymbol(e.target.value)} disabled={isSymbolDisabled}/>
                </label>
                <label>
                    Buy Range
                    <input type="text" value={buyRange} onChange={(e) => setBuyRange(e.target.value)} />
                </label>
                <label>
                    Call Price
                    <input type="text" value={callPrice} onChange={(e) => setCallPrice(e.target.value)}/>
                </label>
                <label>
                    Current Price (CMP)
                    <input type="text" value={currentPrice} onChange={(e) => setCurrentPrice(e.target.value)}/>
                </label>
                <label>
                    Stop Loss
                    <input type="text" value={stopLoss} onChange={(e) => setStopLoss(e.target.value)} />
                </label>
                <label>
                    Target Price
                    <input type="text" value={targetPrice} onChange={(e) => setTargetPrice(e.target.value)} />
                </label>
                <label>
                    Time Frame
                    <input type="text" value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)} />
                </label>
                <label>
                    Equity Status
                        <select value={equityStatus} onChange={(e) => setEquityStatus(e.target.value)}>
                            <option value="BOOKLOSS">Book Loss</option>
                            <option value="BOOKPROFIT">Book Profit</option>
                            <option value="CLOSED">Closed</option>
                            <option value="OPEN">Open</option>
                            <option value="WATCH">Watch List</option>
                        </select>
                </label>
                <label>
                    Fundamental Reasons
                    <input type="text" value={fundamental} onChange={(e) => setFundamental(e.target.value)} />
                </label>
                <label>
                    Technical Reasons
                    <input type="text" value={technical} onChange={(e) => setTechnical(e.target.value)} />
                </label>
                <label>
                    Conclusion
                    <input type="text" value={conclusion} onChange={(e) => setConclusion(e.target.value)} />
                </label>
                <div className="modal-buttons">
                    <button className="save-button" onClick={handleSave}>Save</button>
                    <button className="close-button" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
}

export default AddStockModal;